@charset "UTF-8";
.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}

.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  display: flex;
  max-width: 100%;
  max-height: 100%;
  z-index: 1000;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 0;
  z-index: 1000;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

.cdk-overlay-backdrop-showing {
  opacity: 1;
}

@media (forced-colors: active) {
  .cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }
}
.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}

.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing, .cdk-high-contrast-active .cdk-overlay-transparent-backdrop {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
  z-index: 1000;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

body {
  --bg: white;
  --bg-accent: rgba(0, 0, 0, 0.05);
  --bg-hover: rgba(255, 255, 255, 0.2);
  --bg-active: rgba(255, 255, 255, 0.4);
  --text: black;
  --visited: #551a8b;
  --blue: rgba(207, 221, 255, 0.7);
  --active: #da0000;
  --info: gray;
  --tag: #3d3dbb;
  --toggle: #525252;
  --toggle-accent: #777;
  --toggle-active: #888;
  --toggle-border: transparent;
  --tag-accent: #f3ebc7;
  --form-bg: #3b3b3b;
  --border: black;
  --border-accent: rgba(0, 0, 0, 0.4);
  --border-light: rgba(0, 0, 0, 0.2);
  --unselected-tab: #b3b9c4;
  --error: #da0000;
  --error-bg: #ffa9a9;
  --warning: #c1c500;
  --mod: #136500;
  --card: #f3ebc7;
  --placeholder: #efebdf;
  --deleting: #ffa9a9;
}

html, body {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-size: 16px;
}

body.fullscreen {
  overscroll-behavior: none;
}
body.fullscreen > * {
  display: none;
}
body.fullscreen > .cdk-overlay-container {
  display: block;
}

input[type=color],
input[type=date],
input[type=datetime],
input[type=datetime-local],
input[type=email],
input[type=month],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
input[type=week],
select,
select:focus,
textarea {
  font-size: 14px;
}

textarea {
  min-width: 20px;
  min-height: 100px;
  max-width: 100vw;
  max-height: 100vh;
}

.debug .subscription-bar:before {
  color: var(--error);
  font-size: 14px;
  content: "DEBUG!";
}
.debug footer p {
  min-height: 72px;
  line-height: 72px;
  background-repeat: repeat-x !important;
  background-image: url("~/assets/debug.png") !important;
  border-top: 2px solid var(--border);
  border-bottom: 2px solid var(--border);
}
.debug footer p.version-debug {
  font-size: 0;
}

.error {
  font-size: 80%;
  color: var(--error);
}

.error-404, .error-403 {
  color: var(--error);
}

.error-404.submit-button {
  display: inline-block;
  margin: auto;
  color: var(--text);
  border: 1px solid var(--border);
}

.hidden-without-removing {
  pointer-events: none;
  user-select: none;
  position: absolute;
  opacity: 0;
}

.tag {
  color: var(--tag) !important;
}

.pinned .link :link, .pinned .link :visited, .pinned .link :active {
  color: var(--mod) !important;
}

.update {
  font-size: 80%;
  color: var(--text);
  text-decoration: underline;
}

.log {
  padding: 8px;
  border-radius: 4px;
  background-color: var(--card);
  backdrop-filter: blur(1px);
}
.log progress {
  width: 100%;
}

hr {
  border: none;
  border-top: 1px groove var(--border);
}

.summary-box {
  max-width: 60em;
  margin: 8px;
  padding: 8px;
  border-radius: 8px;
  border: 1px dashed transparent;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
}

.login-popup {
  background-color: var(--error-bg);
}

.resize-handle {
  touch-action: none;
  position: relative;
}
.resize-handle:before {
  z-index: 10;
  content: "";
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjIwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCBtZWV0IiB2aWV3Qm94PSIwIDAgMjAgMjAiIHdpZHRoPSIyMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyIiBjeT0iMTQiIHI9IjEuNSIvPjxjaXJjbGUgY3g9IjgiIGN5PSIxNCIgcj0iMS41Ii8+PGNpcmNsZSBjeD0iOCIgY3k9IjgiIHI9IjEuNSIvPjxjaXJjbGUgY3g9IjE0IiBjeT0iMiIgcj0iMS41Ii8+PGNpcmNsZSBjeD0iMTQiIGN5PSIxNCIgcj0iMS41Ii8+PGNpcmNsZSBjeD0iMTQiIGN5PSI4IiByPSIxLjUiLz48cGF0aCBkPSJtMCAwaDM2djM2aC0zNnoiIGZpbGw9Im5vbmUiLz48L3N2Zz4=");
  position: absolute;
  right: -2px;
  bottom: -2px;
  filter: drop-shadow(0.5px 0.5px 1.25px white);
}

.nowrap {
  white-space: nowrap;
}

.spacer {
  pointer-events: none;
  flex-grow: 1;
}

.dn {
  display: none !important;
}

:link, .fake-link {
  text-decoration: none;
}

.recolour {
  filter: grayscale(1) sepia(1) saturate(1.5) brightness(1.5) hue-rotate(180deg);
}

:link:hover,
.fake-link:hover {
  text-decoration: underline;
}

.no-underline, .no-underline:active, .no-underline:hover, .no-underline:visited {
  text-decoration: none;
}

.fake-link {
  cursor: pointer;
}

.no-select {
  user-select: none;
}

label.big {
  padding-top: 5px;
}

button {
  padding: 1px 6px;
  min-width: 28px !important;
  min-height: 22px !important;
}

.form-array > button, .buttons > button {
  min-width: 32px !important;
  min-height: 24px !important;
}

.form-array > button, .controls > button {
  min-height: 22px;
  padding: 0 2px;
}

.dropping {
  border-radius: 8px;
  padding: 8px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
  background-color: var(--bg-hover);
}
.dropping * {
  pointer-events: none !important;
}

.preview {
  cursor: text;
  pointer-events: all;
  text-decoration: none;
}

.hotkey .preview {
  cursor: pointer;
  text-decoration: underline;
}

input:not([type=checkbox]):not([type=radio]) {
  height: 24px;
}
input:not([type=checkbox]):not([type=radio]).big {
  height: 29px;
}
input:not([type=checkbox]):not([type=radio])[disabled] {
  border: 2px solid var(--bg);
  text-decoration: underline;
  font-weight: bold;
}

input:not([type=checkbox]):not([type=radio]),
textarea {
  box-sizing: border-box;
  padding-block: 2px;
  padding-inline: 8px;
}

button,
input,
textarea,
select {
  color: var(--text);
}

select {
  height: 24px;
  margin: 0 auto;
}
select.big {
  outline: none !important;
  cursor: pointer;
  min-width: 72px;
  min-height: 29px;
  line-height: 29px;
  font-size: 16px;
  padding: 3px 6px;
}

.wide {
  width: 100%;
}

.padded {
  padding: 4px;
}

.padded > * {
  margin: 4px;
}

.cdk-overlay-backdrop {
  backdrop-filter: blur(4px);
}

.cdk-overlay-pane {
  pointer-events: none;
}
.cdk-overlay-pane * {
  pointer-events: all;
}

.cdk-drop-list-dragging,
.cdk-drop-list-receiving {
  cursor: grabbing !important;
}

.floating-ribbons > * {
  transition: right 0.1s cubic-bezier(0.47, 1.64, 0.8, 1.2);
  transition-delay: 0.1s;
}

.floating-toggles, .floating-ribbons {
  position: absolute;
  pointer-events: none;
}
.floating-toggles *, .floating-ribbons * {
  pointer-events: all;
}
.floating-toggles.big *, .floating-ribbons.big * {
  margin: 16px;
  font-size: 300%;
}

.editor {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
}
.editor.add-button {
  padding: 0 !important;
  border: none !important;
  background-color: transparent !important;
  backdrop-filter: none !important;
  box-shadow: none !important;
}
.editor.stacked {
  display: block;
}
.editor .editor-container {
  display: contents;
}
.editor .text-wrapper {
  position: relative;
}
.editor .floating-ribbons {
  right: 0;
  top: 32px;
}
.editor .toolbar {
  display: none;
  flex-direction: row-reverse;
}
.editor .toolbar > :first-child {
  margin-right: 0;
}
.editor .toolbar > * {
  flex-shrink: 0;
  height: 28px;
  min-width: 32px !important;
  margin: 4px 4px 8px;
}
.editor .toolbar > button {
  min-width: 28px;
}
.editor .toolbar label {
  border: 1px dashed var(--border) !important;
}
.editor .toolbar label.on {
  border: 0.5px solid var(--border) !important;
}
.editor .toolbar .toolbar-toggle {
  position: relative;
  background-color: var(--toggle);
  border-radius: 6px;
  height: 28px;
  white-space: nowrap;
}
.editor .toolbar .toolbar-toggle::before {
  content: " ";
  position: absolute;
  width: 26px;
  height: 26px;
  top: 0;
  left: -1px;
  transition: left 300ms ease-in-out;
  border: 1px dashed var(--border);
  border-radius: 6px;
  pointer-events: none;
}
.editor .toolbar .toolbar-toggle button {
  border: none;
  background: transparent;
  user-select: none;
  cursor: pointer;
  margin: 0;
  padding: 5px 0;
}
.editor .toolbar-toggle-1::before {
  left: 28px !important;
}
.editor .toolbar-toggle-2::before {
  left: 56px !important;
}
.editor .toolbar-toggle-3::before {
  left: 84px !important;
}
.editor .toolbar-toggle-4::before {
  left: 112px !important;
}
.editor .toolbar-toggle-5::before {
  left: 140px !important;
}
.editor:focus-within .toolbar, .editor.editing .toolbar {
  display: flex;
}

.editor-label, .add-plugins-label {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

.floating-ribbons {
  user-select: none;
  font-size: 80%;
  font-weight: bold;
  position: absolute;
  right: 0;
  top: 32px;
}
.floating-ribbons > div {
  position: relative;
  white-space: nowrap;
  cursor: pointer;
  right: -100%;
  margin: 4px !important;
}
.floating-ribbons > div span {
  margin-right: auto;
  display: inline-block;
  max-width: 128px;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 2px;
  padding: 2px;
  border: 1px dashed var(--border);
}
.floating-ribbons > div.on {
  right: 16px;
  text-align: right;
}
.floating-ribbons > div.on span {
  backdrop-filter: blur(1.5px);
  border: 0.5px solid var(--border);
}

.editor:focus-within, .bubble, .plugins-form > *, blockquote, .full-page .todo-list {
  border-radius: 8px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
}

.bubble, .plugins-form > *, blockquote {
  margin-top: 8px;
  padding: 8px;
  border: 1px dashed transparent;
}
.bubble hr, .plugins-form > * hr, blockquote hr {
  border: none;
  border-top: 0.5px dotted var(--border);
  position: relative;
  left: -8px;
  width: calc(100% + 16px);
}
.bubble:first-child, .plugins-form > *:first-child, blockquote:first-child {
  margin: 0;
}

.bubble:hover, .plugins-form > *:hover {
  border: 1px dashed var(--border);
}

.bubble .toolbar {
  display: flex;
}

.toolbar .editor-toggle {
  user-select: none;
  font-size: 80%;
  font-weight: bold;
  position: relative;
}
.toolbar .editor-toggle label {
  position: relative;
  top: -3px;
  transform: rotate(-3deg);
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  max-width: 64px;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 2px;
  padding: 2px;
  border: 1px solid transparent;
  transition: all 0.1s cubic-bezier(0.47, 1.64, 0.8, 1.2);
  transition-delay: 0.1s;
}
.toolbar .editor-toggle label.on {
  top: 16px;
  transform: rotate(0);
}
.toolbar .editor-toggle label:not(.on):hover {
  transform: rotate(-4deg) scale(1.05);
}

.editor-help-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.editor-help-popup > * {
  padding: 16px;
  max-width: 300px;
  border-radius: 2px;
  margin: 8px;
  background-color: var(--card);
  border: 1px solid transparent;
}
.editor-help-popup > *:hover {
  border: 1px solid var(--border);
}

video.qr-preview {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  overflow: hidden;
}

.context-menu {
  user-select: none;
  border-radius: 2px;
  padding: 8px;
  white-space: nowrap;
  background-color: var(--card);
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.context-menu > * {
  display: block;
  border-radius: 2px;
  margin: 4px;
  padding: 4px;
  cursor: pointer;
}
.context-menu > *:hover {
  background-color: var(--bg-hover);
}

.parent-link {
  max-width: 75em;
  display: block;
  font-size: 80%;
  font-weight: bold;
  color: var(--info);
  text-align: center;
  padding: 16px;
  margin: 8px;
}

.submit-button {
  white-space: nowrap;
  text-transform: capitalize;
  border: 1px solid var(--border);
  border-radius: 2px;
  margin: 4px 0;
  padding: 4px;
  cursor: pointer;
  overflow: hidden;
  user-select: none;
  transition: background-color 0.2s;
}
.submit-button:hover {
  background-color: var(--bg-hover);
}
.submit-button:hover:active {
  background-color: var(--bg-active);
}
.submit-button label {
  margin-left: 16px;
}
.submit-button * {
  cursor: pointer;
}

.warning {
  font-size: 80%;
  font-style: italic;
  padding: 10px;
}

.error-404, .error-403, .ban-message {
  font-size: 300%;
  text-align: center;
}

.error-404.submit-button {
  font-size: 100%;
  white-space: nowrap;
  text-transform: capitalize;
  border: 1px solid transparent;
  padding: 4px;
  cursor: pointer;
  overflow: hidden;
}

.add-more,
.overwrite.warning,
.force.error {
  display: flex;
  align-items: center;
  font-size: 100% !important;
  margin-right: 16px !important;
  padding: 0 !important;
}
.add-more input,
.overwrite.warning input,
.force.error input {
  margin: 4px;
}

.tag,
.icon:not(:empty) {
  margin: 0 4px;
  line-height: 10px;
  cursor: pointer;
  font-style: normal;
}
.tag + .tag, .tag + .icon,
.icon:not(:empty) + .tag,
.icon:not(:empty) + .icon {
  margin-left: 0;
}

.hotkey .filter-toggle {
  display: inline-block;
  animation-duration: 1s;
  animation-delay: -0.5s;
  animation-name: unfilter;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
}

@keyframes unfilter {
  from {
    transform: translateY(-2px) rotate(20deg);
  }
  50% {
    transform: translateY(3px) rotate(0);
  }
  to {
    transform: translateY(-2px) rotate(-20deg);
  }
}
.deleted {
  text-decoration: line-through;
  opacity: 0.5;
}

.subscription-bar {
  user-select: none;
  font-size: 10px;
  border-bottom: 1px solid transparent;
  padding: 4px 8px 2px 0;
  background-color: var(--bg);
  border-bottom: 1px solid black;
}
.subscription-bar :link, .subscription-bar :visited, .subscription-bar a:active {
  color: var(--text);
}
.subscription-bar .current-page {
  color: var(--active);
}
.subscription-bar .back-button {
  display: none;
  cursor: pointer;
  font-size: 18px;
  margin: -1px 0;
  padding-left: 4px;
  line-height: 18px;
  filter: grayscale(1);
}
.subscription-bar .drafts {
  margin: 0 10px;
  cursor: pointer;
  padding-top: 2.5px;
  position: relative;
  transition: transform 0.15s ease-in-out;
  transform-origin: center;
  transform: scale(1.25);
}
.subscription-bar .drafts:hover {
  transform: scale(1.75);
}
.subscription-bar .preferences {
  white-space: nowrap;
  margin: 0 10px;
  cursor: pointer;
  padding-top: 2.5px;
  position: relative;
  transition: transform 0.15s ease-in-out;
  transform-origin: center;
  transform: scale(1.25);
}
.subscription-bar .preferences:hover {
  transform: scale(1.75);
}
.subscription-bar a {
  text-transform: uppercase;
  padding: 2px;
}
.subscription-bar .current-page {
  font-weight: bold;
}
.subscription-bar .subs {
  font-size: 12px;
  margin-top: 2px;
  mask-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.1) 2px, white 8px, white calc(100% - 18px), rgba(255, 255, 255, 0.1) calc(100% - 6px), rgba(255, 255, 255, 0));
}
.subscription-bar .subs *:first-child {
  margin-left: 10px;
}
.subscription-bar .subs *:last-child {
  margin-right: 10px;
}
.subscription-bar .light-toggle {
  position: relative;
  cursor: pointer;
  margin-left: 10px;
  padding: 2px 15px 15px 20px;
  border-radius: 16px;
  transition: all 0.2s;
  border: 0.5px solid transparent;
}
.subscription-bar .light-toggle:hover {
  border: 0.5px dashed var(--border);
}
.subscription-bar .light-toggle div {
  position: absolute;
  transition: all 0.4s ease-in-out;
}
.subscription-bar .light-toggle div.moon.lights-on {
  transform: translate(-18px);
}
.subscription-bar .light-toggle div.light.lights-off {
  transform: rotate(-180deg);
}
.subscription-bar .light-toggle div.light.lights-on {
  transform: translate(-18px);
}
.subscription-bar .light-toggle div.moon.lights-off, .subscription-bar .light-toggle div.light.lights-on {
  opacity: 1;
}
.subscription-bar .light-toggle div.moon.lights-on, .subscription-bar .light-toggle div.light.lights-off {
  opacity: 0;
}

.tabs {
  display: flex;
  user-select: none;
  flex-wrap: wrap;
  min-height: 60px;
  height: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100vw;
  border-bottom: 1px solid var(--border);
}
.tabs.measuring > a:not(.logo):not(.current-tab) {
  position: absolute;
  opacity: 0;
}
.tabs .logo {
  width: 120px;
  height: 56px;
  background-color: transparent !important;
  border: none !important;
  background-position: 4px 0;
  background-repeat: no-repeat;
}
.tabs > * {
  align-self: flex-end;
}
.tabs.empty > a:not(.logo), .tabs.empty > app-mobile-tab-select {
  mask-image: gradient(linear, left top, left bottom, from(rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)));
}
.tabs h5 {
  margin: 4px;
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
}
.tabs.floating-tabs > a:not(.logo) {
  display: none !important;
}
.tabs.floating-tabs .current-tab {
  display: block;
  border: 1px solid var(--border) !important;
  margin: 4px;
  flex-basis: 100%;
  border-radius: 4px;
}
.tabs .mobile-tab-select {
  margin: 0 4px;
}
.tabs .mobile-tab-select select {
  appearance: none;
  border-radius: 0;
  font-size: 16px;
  height: 25px !important;
  line-height: 25px;
  width: 44px;
  padding: 0 10px;
  text-align: center;
  position: relative;
  outline: none !important;
  cursor: pointer;
  background-color: var(--unselected-tab);
  border: none;
}
.tabs > a:not(.logo) {
  display: inline-block;
  position: relative;
  bottom: 0;
  margin: 0 4px;
  padding: 2px 8px;
  font-weight: bold;
  line-height: 21px;
  background-color: var(--unselected-tab);
}
.tabs > a:not(.logo):link:hover, .tabs > a:not(.logo).fake-link:hover {
  text-decoration: none;
}
.tabs > a:not(.logo):link, .tabs > a:not(.logo):visited, .tabs > a:not(.logo):active, .tabs > a:not(.logo).fake-link {
  color: black !important;
}
.tabs > a:not(.logo).current-tab {
  color: var(--active) !important;
  display: inline-block !important;
  background-color: var(--bg);
  bottom: -1px;
  border: 1px solid var(--border);
  border-bottom: 1px solid transparent;
}
.tabs .settings {
  margin-left: auto;
  border-top-left-radius: 8px;
  display: inline-block;
  justify-self: flex-end;
  user-select: none;
  font-size: 80%;
  line-height: 15px;
  padding: 4px;
  font-weight: bold;
  background-color: var(--unselected-tab);
}
.tabs .settings .author {
  color: var(--tag) !important;
}
.tabs .settings > a:link, .tabs .settings > a:visited, .tabs .settings > a:active, .tabs .settings > a.fake-link {
  color: black !important;
}

.sidebar {
  position: relative;
  float: right;
  overflow: hidden;
  transition: width 0.5s;
  width: 416px;
  margin-bottom: 100px;
  margin-left: 4px;
  border-bottom-left-radius: 4px;
}
.sidebar.floating {
  float: none;
  position: absolute;
  right: 0;
}
.sidebar .toggle {
  margin-top: 3px;
}
.sidebar .row {
  padding: 0 2px;
}
.sidebar .row app-search, .sidebar .row app-filter, .sidebar .row app-sort {
  flex-grow: 1;
}
.sidebar app-filter {
  margin-right: 8px;
}
.sidebar details.advanced summary {
  margin: 4px -8px;
}
.sidebar > * {
  margin: 4px;
}
.sidebar:not(.expanded) {
  width: 40px;
}
.sidebar:not(.expanded) .tag-header, .sidebar:not(.expanded) .ui, .sidebar:not(.expanded) .md, .sidebar:not(.expanded) .sidebar-link, .sidebar:not(.expanded) .ext-text, .sidebar:not(.expanded) .breadcrumbs {
  display: none;
}
.sidebar input[type=search] {
  margin-top: 3px;
  height: 26px;
  width: 368px;
}
.sidebar .controls select, .sidebar .controls button {
  height: 22px;
}
.sidebar app-query input, .sidebar app-query button {
  height: 27px !important;
}
.sidebar .breadcrumbs {
  text-align: center;
}
.sidebar .breadcrumbs * {
  margin: 0;
}
.sidebar .show-remotes {
  display: flex;
  justify-content: flex-end;
}
.sidebar .show-remotes label {
  align-self: center;
  font-size: 75%;
  margin-right: 10px;
  line-height: 13px;
}
.sidebar .button-bar {
  display: flex;
  flex-direction: column;
}
.sidebar .submit-button {
  margin: 4px 0;
}
.sidebar .tag-header {
  text-align: center;
}
.sidebar .tag-header .subscribe, .sidebar .tag-header .bookmark {
  margin: 10px;
}
.sidebar .tag-header .alarm {
  margin: 10px 0 10px -8px;
  padding: 1px;
}
.sidebar .sidebar-link {
  display: block;
  text-align: center;
  font-size: 80%;
}
.sidebar .ext-text {
  padding: 4px;
}
.sidebar .ext-text a {
  display: inline-block;
  margin: 2px 10px;
}
.sidebar .bulk.actions .action > * {
  display: block;
}
.sidebar .md table {
  width: 100%;
  table-layout: fixed;
}
.sidebar .ref, .sidebar .ext {
  white-space: nowrap;
}
.sidebar .ref .info, .sidebar .ref .actions, .sidebar .ref .advanced-actions *, .sidebar .ext .info, .sidebar .ext .actions, .sidebar .ext .advanced-actions * {
  font-size: 16px;
  line-height: 28px;
  height: 28px;
  padding-top: 0;
  padding-bottom: 0;
  overflow-y: hidden;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.sidebar .ref .info::-webkit-scrollbar, .sidebar .ref .actions::-webkit-scrollbar, .sidebar .ref .advanced-actions *::-webkit-scrollbar, .sidebar .ext .info::-webkit-scrollbar, .sidebar .ext .actions::-webkit-scrollbar, .sidebar .ext .advanced-actions *::-webkit-scrollbar {
  display: none;
}
.sidebar .ref .info a, .sidebar .ref .info .fake-link, .sidebar .ref .actions a, .sidebar .ref .actions .fake-link, .sidebar .ref .advanced-actions * a, .sidebar .ref .advanced-actions * .fake-link, .sidebar .ext .info a, .sidebar .ext .info .fake-link, .sidebar .ext .actions a, .sidebar .ext .actions .fake-link, .sidebar .ext .advanced-actions * a, .sidebar .ext .advanced-actions * .fake-link {
  line-height: 28px;
}
.sidebar .ref {
  max-width: 100vw;
}
.sidebar .ref .row {
  max-width: calc(100vw - 12px);
}
.sidebar .ref .info, .sidebar .ref .actions {
  height: 32px;
  margin-left: -8px;
  padding: 0 8px;
  mask-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.1) 2px, white 8px, white calc(100% - 18px), rgba(255, 255, 255, 0.1) calc(100% - 6px), rgba(255, 255, 255, 0));
}
.sidebar .ref .info.expanded, .sidebar .ref .actions.expanded {
  height: 32px;
  padding-top: 2px;
}

.hotkey .sidebar .breadcrumbs .tag {
  color: var(--text);
  text-decoration: unset;
  cursor: text;
}
.hotkey .form .breadcrumbs .tag span {
  text-decoration: underline !important;
  cursor: pointer !important;
}

details.advanced {
  transition: max-height 1s;
  max-height: 37px;
  overflow: hidden;
}
details.advanced summary {
  display: block;
  text-align: right;
}
details.advanced summary:hover {
  background-color: var(--bg-hover);
}
details.advanced summary:hover:active {
  background-color: var(--bg-active);
}
details.advanced summary::marker, details.advanced summary::-webkit-details-marker {
  display: none;
  opacity: 0;
}
details.advanced summary:first-of-type {
  list-style-type: none;
}
details.advanced summary > span {
  display: block;
  cursor: pointer;
  padding: 4px;
  border: 1px solid var(--border);
  border-left: none;
  border-right: none;
  transition: background-color 0.2s;
}
details.advanced summary > span:after {
  margin: 0 8px;
  content: "🔅";
  display: inline-block;
  transition: transform 0.4s;
  transform: rotate(0);
}

details.advanced[open] {
  max-height: 1000px;
}
details.advanced[open] summary > span:after {
  content: "🔆";
  transform: rotate(-120deg);
}
details.advanced[open] .summary-closed {
  display: none;
}

details.mini:not([open]) {
  float: right;
  margin: -4px 0 4px 0 !important;
}
details.mini:not([open]) summary > span {
  border: none !important;
}
details.mini:not([open]) .summary-open {
  display: none;
}

.row {
  display: flex;
}
.row.center {
  justify-content: center;
}
.row .stretch {
  flex-basis: 100%;
}

.col {
  display: flex;
  flex-direction: column;
}
.col .center {
  text-align: center;
}

.list-container {
  display: grid;
  grid-template-columns: min-content auto;
}
.list-container .list-number, .list-container .label {
  color: var(--info);
  text-align: right;
  white-space: nowrap;
  padding: 0 10px;
  font-weight: bold;
  height: 100%;
  min-width: 18px;
  max-height: 57px;
  line-height: 57px;
}

.backup .list-container .list-number {
  max-height: 48px;
  line-height: 48px;
}

.grow {
  flex: 1;
  flex-basis: 100% !important;
  display: flex;
  flex-direction: column;
}
.grow input {
  flex: 1;
  flex-basis: 100% !important;
}

progress.grow {
  height: 100%;
}

.no-margin {
  margin: 0 !important;
}

.max-form-width,
.form,
formly-list-section .form-array > formly-field > formly-group {
  max-width: 600px;
}

.form,
formly-list-section .form-array > formly-field > formly-group {
  display: grid !important;
  grid-template-columns: min-content auto;
  grid-auto-rows: min-content;
  padding: 10px;
}
.form .shrink select,
formly-list-section .form-array > formly-field > formly-group .shrink select {
  margin-top: 2px;
}
.form .nested-title,
formly-list-section .form-array > formly-field > formly-group .nested-title {
  text-align: left;
  margin: 12px 4px 0;
}
.form .nested-form,
.form .field,
.form formly-field,
.form formly-group,
.form formly-wrapper-form-field,
.form formly-list-section,
formly-list-section .form-array > formly-field > formly-group .nested-form,
formly-list-section .form-array > formly-field > formly-group .field,
formly-list-section .form-array > formly-field > formly-group formly-field,
formly-list-section .form-array > formly-field > formly-group formly-group,
formly-list-section .form-array > formly-field > formly-group formly-wrapper-form-field,
formly-list-section .form-array > formly-field > formly-group formly-list-section {
  margin: 0;
  display: contents;
}
.form .nested-form > *,
.form .field > *,
.form formly-field > *,
.form formly-group > *,
.form formly-wrapper-form-field > *,
.form formly-list-section > *,
formly-list-section .form-array > formly-field > formly-group .nested-form > *,
formly-list-section .form-array > formly-field > formly-group .field > *,
formly-list-section .form-array > formly-field > formly-group formly-field > *,
formly-list-section .form-array > formly-field > formly-group formly-group > *,
formly-list-section .form-array > formly-field > formly-group formly-wrapper-form-field > *,
formly-list-section .form-array > formly-field > formly-group formly-list-section > * {
  margin: 4px;
}
.form .nested-form > div:empty, .form .nested-form > span:empty,
.form .field > div:empty,
.form .field > span:empty,
.form formly-field > div:empty,
.form formly-field > span:empty,
.form formly-group > div:empty,
.form formly-group > span:empty,
.form formly-wrapper-form-field > div:empty,
.form formly-wrapper-form-field > span:empty,
.form formly-list-section > div:empty,
.form formly-list-section > span:empty,
formly-list-section .form-array > formly-field > formly-group .nested-form > div:empty,
formly-list-section .form-array > formly-field > formly-group .nested-form > span:empty,
formly-list-section .form-array > formly-field > formly-group .field > div:empty,
formly-list-section .form-array > formly-field > formly-group .field > span:empty,
formly-list-section .form-array > formly-field > formly-group formly-field > div:empty,
formly-list-section .form-array > formly-field > formly-group formly-field > span:empty,
formly-list-section .form-array > formly-field > formly-group formly-group > div:empty,
formly-list-section .form-array > formly-field > formly-group formly-group > span:empty,
formly-list-section .form-array > formly-field > formly-group formly-wrapper-form-field > div:empty,
formly-list-section .form-array > formly-field > formly-group formly-wrapper-form-field > span:empty,
formly-list-section .form-array > formly-field > formly-group formly-list-section > div:empty,
formly-list-section .form-array > formly-field > formly-group formly-list-section > span:empty {
  height: 0;
  margin: 0;
}
.form .nested-form > label,
.form .field > label,
.form formly-field > label,
.form formly-group > label,
.form formly-wrapper-form-field > label,
.form formly-list-section > label,
formly-list-section .form-array > formly-field > formly-group .nested-form > label,
formly-list-section .form-array > formly-field > formly-group .field > label,
formly-list-section .form-array > formly-field > formly-group formly-field > label,
formly-list-section .form-array > formly-field > formly-group formly-group > label,
formly-list-section .form-array > formly-field > formly-group formly-wrapper-form-field > label,
formly-list-section .form-array > formly-field > formly-group formly-list-section > label {
  text-align: right;
  line-height: 24px;
}
.form > *,
formly-list-section .form-array > formly-field > formly-group > * {
  margin: 4px;
}
.form > div:empty, .form > span:empty,
formly-list-section .form-array > formly-field > formly-group > div:empty,
formly-list-section .form-array > formly-field > formly-group > span:empty {
  height: 0;
  margin: 0;
}
.form > label,
formly-list-section .form-array > formly-field > formly-group > label {
  text-align: right;
  line-height: 24px;
}
.form label,
formly-list-section .form-array > formly-field > formly-group label {
  user-select: none;
  white-space: nowrap;
}
.form .block-form,
formly-list-section .form-array > formly-field > formly-group .block-form {
  display: flex;
  align-items: center;
}

.show-drops .nested-form > div:empty, .show-drops .nested-form > span:empty,
.show-drops .field > div:empty,
.show-drops .field > span:empty,
.show-drops formly-field > div:empty,
.show-drops formly-field > span:empty,
.show-drops formly-group > div:empty,
.show-drops formly-group > span:empty,
.show-drops formly-wrapper-form-field > div:empty,
.show-drops formly-wrapper-form-field > span:empty,
.show-drops formly-list-section > div:empty,
.show-drops formly-list-section > span:empty {
  height: unset !important;
  margin: unset !important;
}

.form-group > * {
  margin: 4px 0;
}
.form-group > *:first-child {
  margin-top: 0;
}
.form-group > *:last-child {
  margin-bottom: 0;
}

.form-array, .buttons, .controls {
  display: flex;
  flex: 1;
}
.form-array.right, .buttons.right, .controls.right {
  justify-content: right;
}
.form-array > *, .buttons > *, .controls > * {
  margin-right: 4px;
}
.form-array > *:last-child, .form-array > * input[file], .buttons > *:last-child, .buttons > * input[file], .controls > *:last-child, .controls > * input[file] {
  margin-right: 0 !important;
}
.form-array.skip-margin *, .buttons.skip-margin *, .controls.skip-margin * {
  margin-right: 0 !important;
}
.form-array input, .buttons input, .controls input {
  flex: 1;
  flex-basis: 100%;
}
.form-array select, .buttons select, .controls select {
  margin-left: 0;
}

.drag-handle {
  align-self: center;
  user-select: none;
  cursor: grab;
  transition: transform 100ms ease-in-out;
  transform: scale(2);
}
.drag-handle:after {
  content: "≡";
}
.drag-handle:hover {
  transform: scale(2.4);
}

formly-list-section > .form-group .form-array > formly-field > formly-group {
  width: 100%;
}
formly-list-section > .form-group > .form-array > formly-field > formly-wrapper-form-field > label {
  cursor: grab;
  transition: transform 100ms ease-in-out;
}
formly-list-section > .form-group > .form-array > formly-field > formly-wrapper-form-field > label:hover {
  transform: scale(1.2);
}
formly-list-section > .form-group > .form-array > formly-field > formly-wrapper-form-field > label, formly-list-section > .form-group > .form-array > formly-field > formly-wrapper-form-field > .field > .form-array {
  margin: 0 4px;
}
formly-list-section > .form-group .cdk-drag-placeholder {
  opacity: 0.5;
}
formly-list-section > .form-group .cdk-drag-placeholder > .cdk-drag-handle, formly-list-section > .form-group .cdk-drag-placeholder > formly-field > formly-wrapper-form-field > .cdk-drag-handle {
  visibility: hidden;
}

formly-list-section .form-array:not(:nth-child(2)) > formly-field > formly-group {
  border-top: 0.5px dashed var(--border);
}

.list-drag.cdk-drag-preview {
  overflow: visible;
  cursor: grabbing !important;
}
.list-drag.cdk-drag-preview > formly-field > formly-group,
.list-drag.cdk-drag-preview .field,
.list-drag.cdk-drag-preview button {
  visibility: hidden;
}

formly-field-radio, formly-field-multicheckbox {
  margin: 0;
  display: grid;
  grid-template-columns: min-content auto;
}
formly-field-radio *, formly-field-multicheckbox * {
  margin: 4px;
}
formly-field-radio label, formly-field-multicheckbox label {
  text-align: left;
}

.plugins-form > * {
  display: block;
}
.plugins-form .form {
  padding-bottom: 0 !important;
  max-width: 100%;
}
.plugins-form details.advanced {
  margin: 8px -8px;
}
.plugins-form details.advanced .form {
  padding: 16px;
}
.plugins-form details.mini:not([open]) {
  float: right;
  margin: -4px 4px 4px 0 !important;
}
.plugins-form details.mini:not([open]) summary > span {
  float: none;
  border: none !important;
}
.plugins-form details.mini[open] {
  float: none;
  margin: -23px 4px 4px 0 !important;
}
.plugins-form details.mini[open] summary > span {
  float: right;
  border: none !important;
}
.plugins-form .ribbon-margin {
  height: 0;
  transition: height 0.2s linear;
}
.plugins-form .floating-ribbons {
  right: 0;
  top: 0;
}

.listbox span {
  display: flex;
}
.listbox span span {
  flex: 1;
  padding: 2px;
  border: 1px solid transparent;
}
.listbox span .selected {
  border: 1px dotted var(--border);
}

.page-controls {
  line-height: 24px;
  padding: 2px;
  margin: 10px;
}
.page-controls .disabled {
  pointer-events: none;
  text-decoration: none;
}
.page-controls .disabled:link, .page-controls .disabled:active, .page-controls .disabled:visited {
  color: var(--text) !important;
}

.thumbnail {
  margin-right: 10px;
  width: 48px;
  height: 48px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  color: white;
  overflow: hidden;
}
.thumbnail.default-thumbnail {
  background-image: url("~/assets/default-thumbnail.png");
}

.list-item {
  display: block;
  max-width: 100vw;
  border: 1px solid transparent;
  margin: 2px;
  padding: 2px;
}
.list-item .link-below {
  display: flex;
}

.list-item:not(.full-page):not(.expand).last-selected,
.kanban-card.last-selected,
.note.last-selected,
.comment.last-selected {
  border: 1px dashed var(--border);
}

.email .thumbnail, .email .link {
  display: none;
}
.email .list-item .expand {
  border: none;
}

.ext {
  display: block;
}

.ref.thread textarea,
.ref.plugin_thread textarea {
  max-width: 800px;
}

.ref-list .ref.plugin_thread.plugin_placeholder .expand {
  animation: fadein 2s ease-in-out;
  animation-iteration-count: infinite;
}
.ref-list .ref.plugin_thread.plugin_placeholder .actions, .ref-list .ref.plugin_thread.plugin_placeholder .info {
  transition: opacity 0.2s linear;
  opacity: 0;
}
.ref-list .ref.plugin_thread.plugin_placeholder:hover .actions, .ref-list .ref.plugin_thread.plugin_placeholder:hover .info {
  transition: opacity 0.2s 1s linear;
  opacity: 1;
  animation: none;
}
.ref-list .ref.plugin_thread.plugin_placeholder:not(:hover) {
  transition: border-color 0.2s linear, background-color 0.2s linear, box-shadow 0.2s linear;
  border-color: transparent !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.ref-list .ref.plugin_thread.plugin_placeholder:hover {
  transition: border-color 0.2s 1s linear, background-color 0.2s 1s linear, box-shadow 0.2s 1s linear;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.thread > .ref-list > .list-container > .ref > .embed > .expand, .thread > .ref-list > .list-container > .ref > .row > .stack > .link-below > .stack > .embed > .expand,
.thread .ref.plugin_thread:not(.ref-list-item) > .embed > .expand,
.thread .ref.plugin_thread:not(.ref-list-item) > .row > .stack > .link-below > .stack > .embed > .expand,
.md .ref.plugin_thread:not(.ref-list-item) > .embed > .expand,
.md .ref.plugin_thread:not(.ref-list-item) > .row > .stack > .link-below > .stack > .embed > .expand,
.parent-ref.thread > .embed > .expand,
.parent-ref.thread > .row > .stack > .link-below > .stack > .embed > .expand,
.full-page.ref.thread > .embed > .expand,
.full-page.ref.thread > .row > .stack > .link-below > .stack > .embed > .expand {
  border: none !important;
}

.bubble .ref.expand {
  border: none !important;
}

.ref.upload, .ext.upload {
  --upload-border: 30px;
  --upload-border-margin: -6px;
  position: relative;
  padding-left: calc(var(--upload-border));
}
.ref.upload:before, .ext.upload:before {
  position: absolute;
  pointer-events: none;
  content: " ";
  height: 100%;
  width: var(--upload-border);
  left: var(--upload-border-margin);
  box-sizing: border-box;
  border: var(--upload-border) solid transparent;
  border-left: var(--upload-border) solid var(--warning);
}
.ref.upload.exists:before, .ext.upload.exists:before {
  border-left: var(--upload-border) solid var(--error);
}

.thread > .ref-list > .list-container > .ref,
.thread .ref.plugin_thread:not(.ref-list-item),
.md .ref.plugin_thread:not(.ref-list-item),
.parent-ref.thread,
.full-page.ref.thread {
  max-width: 60em;
  margin: 8px;
  padding: 16px;
  --thread-sent: rgba(39, 78, 124, 0.58);
  --thread-recieved: rgba(128, 128, 128, 0.5);
  border-radius: 32px;
  overflow: hidden;
}
.thread > .ref-list > .list-container > .ref.sent,
.thread .ref.plugin_thread:not(.ref-list-item).sent,
.md .ref.plugin_thread:not(.ref-list-item).sent,
.parent-ref.thread.sent,
.full-page.ref.thread.sent {
  margin-left: 40px;
  background-color: var(--thread-sent);
  border-bottom-right-radius: 2px;
  border: 1px solid rgba(46, 87, 159, 0.3);
}
.thread > .ref-list > .list-container > .ref:not(.sent),
.thread .ref.plugin_thread:not(.ref-list-item):not(.sent),
.md .ref.plugin_thread:not(.ref-list-item):not(.sent),
.parent-ref.thread:not(.sent),
.full-page.ref.thread:not(.sent) {
  background-color: var(--thread-recieved);
  border-bottom-left-radius: 2px;
  border: 1px solid rgba(157, 157, 157, 0.3);
}
.thread > .ref-list > .list-container > .ref.last-selected,
.thread .ref.plugin_thread:not(.ref-list-item).last-selected,
.md .ref.plugin_thread:not(.ref-list-item).last-selected,
.parent-ref.thread.last-selected,
.full-page.ref.thread.last-selected {
  border: 1px dashed var(--border);
}
.thread > .ref-list > .list-container > .ref > .row > .thumbnail,
.thread .ref.plugin_thread:not(.ref-list-item) > .row > .thumbnail,
.md .ref.plugin_thread:not(.ref-list-item) > .row > .thumbnail,
.parent-ref.thread > .row > .thumbnail,
.full-page.ref.thread > .row > .thumbnail {
  display: none;
}
.thread > .ref-list > .list-container > .ref > .row > .stack > .link-below > .stack > .info, .thread > .ref-list > .list-container > .ref > .row > .stack > .link-below > .stack > .actions,
.thread .ref.plugin_thread:not(.ref-list-item) > .row > .stack > .link-below > .stack > .info,
.thread .ref.plugin_thread:not(.ref-list-item) > .row > .stack > .link-below > .stack > .actions,
.md .ref.plugin_thread:not(.ref-list-item) > .row > .stack > .link-below > .stack > .info,
.md .ref.plugin_thread:not(.ref-list-item) > .row > .stack > .link-below > .stack > .actions,
.parent-ref.thread > .row > .stack > .link-below > .stack > .info,
.parent-ref.thread > .row > .stack > .link-below > .stack > .actions,
.full-page.ref.thread > .row > .stack > .link-below > .stack > .info,
.full-page.ref.thread > .row > .stack > .link-below > .stack > .actions {
  margin-left: -8px;
  padding: 0 8px 3px;
  mask-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.1) 2px, white 8px, white calc(100% - 18px), rgba(255, 255, 255, 0.1) calc(100% - 6px), rgba(255, 255, 255, 0));
}

.parent-ref.thread > .row > .stack > .link-below > .toggle {
  display: none;
}

.thread > .ref-list > .list-container > .ref > .row > .stack > .comment-title, .thread > .ref-list > .list-container > .ref > .row > .stack > .link-below > .toggle,
.full-page.ref.thread > .row > .stack > .comment-title,
.full-page.ref.thread > .row > .stack > .link-below > .toggle {
  display: none;
}

.thread > .ref-list > .list-container > .list-number {
  font-size: 60%;
  padding: 0;
  line-height: unset;
  margin-left: -8px;
  transform: translate(12px, 8px);
}
.thread > .ref-list > .list-container > .ref > .row > .stack > .link.redundant, .thread > .ref-list > .list-container > .ref > .row > .thumbnail {
  display: none;
}

.stack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  min-width: 0;
}

.toggle {
  user-select: none;
  display: block;
  clear: both;
  margin: 4px 8px 4px 2px;
  flex-shrink: 0;
  width: 24px !important;
  height: 24px !important;
  min-width: unset !important;
  border-radius: 2px;
  text-align: center;
  line-height: 24px;
  cursor: pointer;
  border: 1px solid var(--toggle-border);
  font-size: 18px;
  padding: 0;
  transition: transform 100ms;
  background-color: var(--toggle);
}
.toggle:hover {
  background-color: var(--toggle-accent);
}
.toggle:hover:active {
  background-color: var(--toggle-active);
  transform: scale(0.99) rotate(2deg) translateY(0.5px);
}
.toggle .toggle-plus,
.toggle .toggle-x {
  position: relative;
}
.toggle.actions-toggle {
  margin: 4px 0 4px 8px;
}
.toggle.threads, .toggle.comments, .toggle.view {
  margin: 4px 8px 4px 8px;
}
.toggle.inline, .toggle.embed {
  display: inline-block;
  margin: 4px;
}

.sidebar .toggle:active {
  transform: scale(0.99) translateY(1px);
}

.comment {
  margin-left: 10px;
}
.comment .voting {
  margin-top: 4px;
  margin-right: 2px;
  font-size: 80%;
  display: inline-block;
}
.comment .voting * {
  transform: none !important;
}
.comment .comment-children {
  margin-left: 18px;
}
.comment .comment-container {
  padding: 2px;
  border: 1px solid transparent;
}
.comment .comment-container:focus-visible {
  border: 1px dashed var(--border);
}
.comment .comment-body, .comment .row > .comment-container > .actions {
  padding: 4px;
  margin-left: 20px;
}
.comment .collapsed {
  font-style: italic;
}
.comment .collapsed .comment-expand {
  font-style: normal;
}
.comment .info .author {
  font-weight: bold;
}

.comment-reply button, .comment-reply .text-wrapper, .comment-edit button, .comment-edit .text-wrapper {
  margin: 5px;
}

.hints {
  float: right;
  display: flex;
  font-size: 75%;
  color: var(--warning);
}
.hints label {
  margin-right: 10px;
  line-height: 13px;
  align-self: center;
}
.hints label sup {
  margin: 0;
}

.link .host {
  margin-left: 4px;
  font-size: 80%;
  color: var(--info);
}

.info {
  font-size: 80%;
  color: var(--info);
}
.info :link, .info :visited, .info a:active {
  color: var(--tag);
}
.info .collapsed :link, .info .collapsed :visited, .info .collapsed a:active {
  color: var(--info);
}

.actions {
  padding: 2px 0;
  height: 14px;
  user-select: none;
}
.actions a, .actions .fake-link {
  margin: 2px 6px 2px 0;
}

.actions, .advanced-actions {
  font-size: 80%;
  font-weight: bold;
  white-space: nowrap;
}
.actions :link, .actions :visited, .actions a:active, .actions .fake-link, .actions .loading-dots, .advanced-actions :link, .advanced-actions :visited, .advanced-actions a:active, .advanced-actions .fake-link, .advanced-actions .loading-dots {
  color: var(--info) !important;
}
.actions input, .advanced-actions input {
  margin: 0;
  font-size: 85%;
  height: 16px !important;
}
.actions input:invalid, .advanced-actions input:invalid {
  color: var(--error);
}
.actions select, .advanced-actions select {
  margin-top: -4px;
  min-height: 20px;
  height: 20px;
}
.actions button, .advanced-actions button {
  position: relative;
  top: 1px;
  margin: 0 6px;
  padding: 0 4px;
  height: 14px;
  min-width: 0 !important;
  min-height: 0 !important;
  line-height: 10px;
}

.hotkey .action.force-delete .fake-link {
  color: var(--active) !important;
}

.ref.parent-ref {
  margin-left: 20px;
}
.ref.parent-ref .thumbnail {
  display: none;
}
.ref.parent-ref .link:before {
  content: "⤴️";
  font-size: 80%;
  padding-right: 8px;
}
.ref.parent-ref .link a {
  font-style: italic;
  font-size: 90%;
}
.ref.parent-ref .link .host {
  font-size: 70%;
}
.ref.parent-ref .info {
  font-size: 70%;
}
.ref.parent-ref .actions {
  display: none;
}

.viewer-inline > * {
  margin: 4px;
}

.viewer-below > * {
  margin: 4px;
}

.expand {
  border-radius: 4px;
  margin: 2px;
  padding: 8px;
  max-width: min(100vw - 16px, 60em);
}
.expand .embed-expand {
  margin: auto;
}

.ref .expand, .form .expand, .editor .expand {
  border: 1px solid var(--border);
}

.image-expand {
  height: 240px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.image-expand.loading {
  border-radius: 7px;
  opacity: 50%;
  animation-duration: 4s;
  animation-name: image-loading;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes image-loading {
  from {
    box-shadow: inset 0 0 5px grey;
  }
  5% {
    box-shadow: inset 0 0 5px grey;
  }
  35% {
    box-shadow: inset 0 0 30px grey;
  }
  70% {
    box-shadow: inset 0 0 30px grey;
  }
  to {
    box-shadow: inset 0 0 5px grey;
  }
}

.video-expand {
  height: 240px;
}

.audio-expand {
  width: calc(100% - 40px);
  max-width: 60em;
  margin: 10px 0;
}

.notification {
  filter: sepia(100%) saturate(400%) brightness(74%) hue-rotate(300deg);
}

.no-results {
  margin-left: 10px;
  color: var(--error);
}

.load-more {
  display: inline-block;
  font-size: 80%;
  font-weight: bold;
  padding: 10px;
  color: var(--info);
}

.empty-plugins {
  font-size: 80%;
  font-style: italic;
}

textarea {
  width: 504px;
  height: 100px;
}

.plugins-form textarea {
  width: 464px;
  height: 100px;
}

footer {
  clear: both;
  margin-top: 100px;
}
footer .version {
  text-align: center;
}

.login-popup {
  position: fixed;
  width: 100vw;
  z-index: 2;
  display: flex;
  justify-content: center;
}
.login-popup > * {
  margin: 10px;
}

.backup.buttons button, input.upload {
  margin: 10px;
}

.validations > div {
  margin: 4px;
}

.description {
  font-size: 80%;
}

.inline-ui, .inline-ui > .md, .inline-ui > .md > *:not(dialog) {
  display: inline !important;
  white-space: nowrap;
}
.inline-ui > br, .inline-ui > .md > br, .inline-ui > .md > *:not(dialog) > br {
  display: none !important;
}

.md, .md blockquote {
  max-width: 60em;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.md > :first-child, .md blockquote > :first-child {
  margin-top: 0;
}
.md > :last-child, .md blockquote > :last-child {
  margin-bottom: 0;
}
.md p, .md blockquote p {
  text-align: justify;
}
.md p > app-nav > .tag, .md blockquote p > app-nav > .tag {
  margin: 0;
}
.md blockquote, .md blockquote blockquote {
  color: var(--info);
  border-left: 2px solid var(--quote);
}
.md del, .md blockquote del {
  color: var(--info);
}
.md figcaption, .md blockquote figcaption {
  margin: 4px;
  padding: 2px;
  font-weight: bold;
  font-size: 80%;
  font-style: italic;
}
.md pre, .md blockquote pre {
  padding: 10px !important;
}
.md hr, .md blockquote hr {
  border: none;
  height: 2px;
  background: var(--info);
  border-top: 1px groove var(--border);
}
.md hr hr, .md blockquote hr hr {
  border: none;
  border-top: 1px groove var(--border-accent);
}
.md td, .md th, .md blockquote td, .md blockquote th {
  padding: 4px;
}
.md code, .md blockquote code {
  border-radius: 2px;
  border: 2px solid var(--bg);
  background: var(--bg);
}
.md pre > code, .md blockquote pre > code {
  border-radius: unset;
  border: none;
  background: transparent;
}
.md table, .md th, .md tr, .md td, .md blockquote table, .md blockquote th, .md blockquote tr, .md blockquote td {
  border: 1px solid var(--info);
  border-collapse: collapse;
}
.md .embed > *, .md blockquote .embed > * {
  margin: 0 auto;
}

ngx-monaco-editor {
  position: relative;
  border: 1px solid var(--border);
}
ngx-monaco-editor .editor-container {
  height: 100% !important;
}

.kanban {
  padding: 4px;
  grid-gap: 8px;
}
.kanban .column-title {
  justify-self: center;
  width: calc(100% - 32px);
}
.kanban .column-title, .kanban .swim-lane-title {
  margin: 8px 0;
  padding: 4px;
  border-radius: 12px !important;
  font-weight: bold;
  text-align: center;
  transform: scale(1);
  transform-origin: center;
  transition: transform 0.1s cubic-bezier(0.47, 1.64, 0.8, 1.2);
}
.kanban .column-title:hover, .kanban .swim-lane-title:hover {
  z-index: 1;
  transform: scale(1.01);
}
.kanban .kanban-column {
  justify-self: center;
  border: 1px solid transparent;
}
.kanban .kanban-column:hover {
  border: 1px dashed var(--border);
}
.kanban .kanban-header {
  margin-top: 8px;
}
.kanban .kanban-header .kanban-remove {
  border-radius: 4px;
  background-color: var(--placeholder);
}
.kanban .kanban-header.cdk-drop-list-dragging .kanban-remove {
  background-color: var(--deleting);
}
.kanban .kanban-header .cdk-drag-placeholder {
  display: none;
}

.kanban-card-container .row *,
.note-container .row * {
  align-self: center;
}

.kanban-card,
.note {
  padding: 4px;
  margin-top: 2px;
  border-radius: 4px;
  background-color: var(--card);
}
.kanban-card .plugin_thread .toggle,
.note .plugin_thread .toggle {
  background-color: var(--toggle-accent);
}
.kanban-card .md p,
.note .md p {
  text-align: left;
}

.kanban-card {
  transform: scale(1);
  transform-origin: center;
  transition: transform 0.1s cubic-bezier(0.47, 1.64, 0.8, 1.2);
}
.kanban-card.unlocked {
  border-radius: 5px;
  border: 0.25px dashed var(--border-accent);
}
.kanban-card:hover {
  filter: drop-shadow(0 0 0.5px var(--border));
}
.kanban-card.cdk-drag-placeholder {
  background-color: var(--placeholder);
}
.kanban-card.no-write {
  cursor: no-drop !important;
}
.kanban-card.no-write.cdk-drag-preview {
  pointer-events: auto !important;
  cursor: no-drop !important;
}
.kanban-card.no-write.cdk-drag-preview * {
  pointer-events: none !important;
}
.kanban-card.unlocked {
  z-index: 1;
  transform: scale(1.05);
}
.kanban-card:hover {
  z-index: 1;
  transform: scale(1.01);
}
.kanban-card:not(.no-write) {
  cursor: grab;
}
.kanban-card .card-title, .kanban-card .thumbnail {
  margin-bottom: 4px;
}
.kanban-card.cdk-drag-preview {
  transition: transform 0s;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.kanban-card.cdk-drag-placeholder {
  transition: transform 0s;
}
.kanban-card.cdk-drag-placeholder * {
  visibility: hidden !important;
}

.form .todo-list > input {
  display: block !important;
}

.nested-form .todo-list {
  padding-bottom: 4px !important;
}
.nested-form .todo-list > input {
  display: block !important;
}
.nested-form .todo-list > .spacer {
  display: none !important;
}

.todo-item {
  padding: 4px;
  margin-top: 2px;
  border-radius: 4px;
  background-color: var(--card);
  transform: scale(1);
  transform-origin: center;
  transition: transform 0.1s cubic-bezier(0.47, 1.64, 0.8, 1.2);
}
.todo-item textarea {
  height: 64px !important;
  resize: none;
}
.todo-item.unlocked {
  border-radius: 5px;
  border: 0.25px dashed var(--border-accent);
}
.todo-item:hover {
  filter: drop-shadow(0 0 0.5px var(--border));
}
.todo-item.cdk-drag-placeholder {
  background-color: var(--placeholder);
}
.todo-item .md p {
  text-align: left;
  margin: 1px;
}
.todo-item.no-write {
  cursor: no-drop !important;
}
.todo-item.no-write.cdk-drag-preview {
  pointer-events: auto !important;
  cursor: no-drop !important;
}
.todo-item.no-write.cdk-drag-preview * {
  pointer-events: none !important;
}
.todo-item.unlocked {
  z-index: 1;
  transform: scale(1.05);
}
.todo-item:hover {
  z-index: 1;
  transform: scale(1.01);
}
.todo-item:not(.no-write) {
  cursor: grab;
}
.todo-item.cdk-drag-preview {
  transition: transform 0s;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.todo-item.cdk-drag-placeholder {
  transition: transform 0s;
}
.todo-item.cdk-drag-placeholder * {
  visibility: hidden !important;
}

.blog .blog-entry {
  display: block;
  max-width: calc(100vw - 8px);
  overflow: hidden;
  border: 1px solid transparent;
  margin: 2px;
  padding: 2px;
}
.blog .blog-entry .blog-title {
  max-width: 40em;
  word-wrap: break-word;
}
.blog .blog-entry .blog-body .expand {
  border: none;
}
.blog .blog-entry .row {
  margin-left: 10px;
}
.blog .blog-entry .link-below {
  display: flex;
}

.chat .odd > * {
  background-color: var(--bg-accent);
  backdrop-filter: blur(1px);
}
.chat .gutter {
  opacity: 0.5;
}
.chat .focused {
  margin: 4px;
}
.chat .messages {
  max-height: 80vh;
}
.chat .messages .cdk-virtual-scroll-content-wrapper {
  display: grid;
  grid-template-columns: min-content auto;
  width: 100%;
}

.desktop {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  max-width: 100vw;
  height: 90vh;
}

.file, .subfolder {
  display: block;
  padding: 6px;
  border-radius: 16px;
  width: 128px;
  min-height: 128px;
  display: inline-block;
  user-select: none;
  text-align: center;
}
.file:hover, .file:active, .file.cdk-drag-dragging, .subfolder:hover, .subfolder:active, .subfolder.cdk-drag-dragging {
  z-index: 1000;
  box-shadow: inset 0 0 5px grey;
  backdrop-filter: blur(3px);
  transition: box-shadow 0.1s ease-out;
}
.file.cdk-drag-dragging, .subfolder.cdk-drag-dragging {
  box-shadow: inset 0 0 10px grey;
  transition: box-shadow 0.1s ease-in;
}
.file .row, .subfolder .row {
  justify-content: center;
}
.file .row a, .subfolder .row a {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.file .thumbnail, .subfolder .thumbnail {
  width: 128px;
  height: 128px;
  line-height: 128px;
  font-size: 64px;
  cursor: default;
}
.file :focus .folder-icon, .file :focus-within .folder-icon, .subfolder :focus .folder-icon, .subfolder :focus-within .folder-icon {
  filter: drop-shadow(0 0 2px var(--border));
}

.chess-piece {
  cursor: grab;
  text-align: center;
  vertical-align: middle;
}
.chess-piece.w {
  color: #DC9;
  filter: drop-shadow(1px 1px 1px black);
}
.chess-piece.b {
  color: #111;
  filter: drop-shadow(0 0 1px rgba(200, 200, 200, 0.5));
}
.chess-piece.cdk-drag-placeholder {
  display: none !important;
}
.chess-piece.cdk-drag-preview {
  font-size: var(--drag-piece-size) !important;
}
.chess-piece.disabled {
  cursor: default !important;
}

.backgammon-piece {
  cursor: grab;
  text-align: center;
  vertical-align: middle;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1), scale 250ms cubic-bezier(0, 0, 0.2, 1);
}
.backgammon-piece.cdk-drag-preview {
  font-size: var(--drag-piece-size) !important;
  transform: scale(1.2);
}
.backgammon-piece.disabled {
  cursor: default !important;
}

.backgammon-spot .backgammon-piece.cdk-drag-placeholder, .backgammon-bar .backgammon-piece.cdk-drag-placeholder, .backgammon-off .backgammon-piece.cdk-drag-placeholder {
  opacity: 0;
}
.backgammon-spot:not(.drag-source) .backgammon-piece.cdk-drag-placeholder, .backgammon-bar:not(.drag-source) .backgammon-piece.cdk-drag-placeholder, .backgammon-off:not(.drag-source) .backgammon-piece.cdk-drag-placeholder {
  height: 0 !important;
}

.cdk-drop-list-dragging .chess-piece {
  transform: scale(0.9) !important;
  transition: transform 0.1s ease-in-out;
}

body.light-theme {
  --bg: white;
  --bg-accent: rgba(0, 0, 0, 0.05);
  --bg-hover: rgba(0, 0, 0, 0.1);
  --bg-active: rgba(0, 0, 0, 0.4);
  --text: black;
  --visited: #551a8b;
  --blue: rgba(207, 221, 255, 0.7);
  --active: #da0000;
  --info: gray;
  --tag: #3d3dbb;
  --toggle: #CCC;
  --toggle-accent: #BBB;
  --toggle-active: #AAA;
  --toggle-border: grey;
  --tag-accent: #f3ebc7;
  --form-bg: white;
  --border: black;
  --border-accent: rgba(0, 0, 0, 0.4);
  --border-light: rgba(0, 0, 0, 0.2);
  --unselected-tab: #b3b9c4;
  --error: #da0000;
  --error-bg: #ffa9a9;
  --warning: #883;
  --mod: #136500;
  --card: #f3ebc7;
  --placeholder: #efebdf;
  --deleting: #ffa9a9;
  --quote: #af5800;
  color-scheme: light;
  color: var(--text);
  background-color: var(--bg);
  background-image: url("~/assets/bg-light.png");
  background-blend-mode: darken;
}
body.light-theme figure::backdrop, body.light-theme .editor.fullscreen app-md {
  background-color: var(--bg);
  background-image: url("~/assets/bg-light.png");
  background-blend-mode: darken;
}
body.light-theme .link-style {
  color: blue;
}
body.light-theme a:active {
  color: var(--text);
}
body.light-theme .debug footer p {
  filter: brightness(3);
}
body.light-theme .toolbar label.on {
  backdrop-filter: contrast(0.2) brightness(1.5) blur(1.5px);
}
body.light-theme .warning {
  text-shadow: 0 0 2px var(--warning);
}
body.light-theme .tag {
  background-color: var(--tag-accent);
  padding: 0 2px;
  border-radius: 2px;
}
body.light-theme .subscription-bar {
  background-color: var(--bg);
  border-bottom: 1px solid black;
}
body.light-theme .subscription-bar :link, body.light-theme .subscription-bar :visited, body.light-theme .subscription-bar a:active {
  color: var(--text);
}
body.light-theme .subscription-bar .current-page {
  color: var(--active);
}
body.light-theme .subscription-bar .light-toggle {
  background-color: rgba(0, 0, 0, 0.2);
}
body.light-theme .subscription-bar .light-toggle:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
body.light-theme .tabs {
  background-color: var(--blue);
}
body.light-theme .tabs .logo {
  background-image: url("~/assets/jasper-light.png");
}
body.light-theme .tabs > a:hover {
  background-color: #dedddd;
}
body.light-theme .tabs > a.current-tab {
  background-image: url("~/assets/bg-light.png");
  background-blend-mode: darken;
}
body.light-theme .tabs select:hover {
  background-color: #dedddd;
}
body.light-theme .sidebar {
  transform: translateY(-1px);
  border-top: 1px solid var(--border);
  border-left: 1px dashed var(--border);
  border-bottom: 1px dashed var(--border);
  backdrop-filter: grayscale(1) contrast(0.2) brightness(1.5) blur(6px);
  box-shadow: -2px 2px 2px 1px rgba(0, 0, 0, 0.1);
}
body.light-theme .editor:focus-within, body.light-theme .bubble, body.light-theme .plugins-form > *, body.light-theme blockquote, body.light-theme .full-page .todo-list {
  backdrop-filter: blur(1px) sepia(5%);
}
body.light-theme .comment-children {
  border-left: 1px dotted rgba(128, 128, 128, 0.3);
}
body.light-theme .thread > .ref-list > .list-container > .ref,
body.light-theme .md .ref.plugin_thread:not(.ref-list-item),
body.light-theme .thread .ref.plugin_thread:not(.ref-list-item),
body.light-theme .thread.ref {
  --thread-sent: rgba(32, 32, 255, 0.1);
  --thread-recieved: rgba(243, 235, 199, 0.3);
  box-shadow: 1px 1px 2.5px rgba(0, 0, 0, 0.8);
}
body.light-theme .summary-box {
  backdrop-filter: blur(1px) sepia(5%);
}
body.light-theme footer hr {
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.08), 0 5px 5px 1px rgba(0, 0, 0, 0.1), 0 4px 0.5px 0 white;
}
body.light-theme .kanban .column-title, body.light-theme .kanban .swim-lane-title {
  background-color: transparent;
  backdrop-filter: blur(1px) sepia(5%);
  border: 0.5px solid var(--border-light);
}
body.light-theme .kanban .column-title:hover, body.light-theme .kanban .swim-lane-title:hover {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}
body.light-theme .backgammon-board {
  --bg: rgba(187, 147, 71, 0.5);
  --black: #333;
  --red: #A55;
  --play: #DDD;
  --brown: #862;
  --brown-alt: #975;
  --glow: rgba(0, 0, 0, 0.5);
  --move: rgba(82, 136, 82, 0.5);
}

/**
* Gruvbox light theme
*
* Based on Gruvbox: https://github.com/morhetz/gruvbox
* Adapted from PrismJS gruvbox-dark theme: https://github.com/schnerring/prism-themes/blob/master/themes/prism-gruvbox-dark.css
*
* @author Michael Schnerring (https://schnerring.net)
* @version 1.0
*/
body.light-theme {
  /* Code blocks */
  /* Inline code */
}
body.light-theme code[class*=language-],
body.light-theme pre[class*=language-] {
  color: #3c3836; /* fg1 / fg */
  font-family: Consolas, Monaco, "Andale Mono", monospace;
  direction: ltr;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
body.light-theme pre[class*=language-]::-moz-selection,
body.light-theme pre[class*=language-] ::-moz-selection,
body.light-theme code[class*=language-]::-moz-selection,
body.light-theme code[class*=language-] ::-moz-selection {
  color: #282828; /* fg0 */
  background: #a89984; /* bg4 */
}
body.light-theme pre[class*=language-]::selection,
body.light-theme pre[class*=language-] ::selection,
body.light-theme code[class*=language-]::selection,
body.light-theme code[class*=language-] ::selection {
  color: #282828; /* fg0 */
  background: #a89984; /* bg4 */
}
body.light-theme pre[class*=language-] {
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
}
body.light-theme :not(pre) > code[class*=language-],
body.light-theme pre[class*=language-] {
  background: #f9f5d7; /* bg0_h */
}
body.light-theme :not(pre) > code[class*=language-] {
  padding: 0.1em;
  border-radius: 0.3em;
}
body.light-theme .token.comment,
body.light-theme .token.prolog,
body.light-theme .token.cdata {
  color: #7c6f64; /* fg4 / gray1 */
}
body.light-theme .token.delimiter,
body.light-theme .token.boolean,
body.light-theme .token.keyword,
body.light-theme .token.selector,
body.light-theme .token.important,
body.light-theme .token.atrule {
  color: #9d0006; /* red2 */
}
body.light-theme .token.operator,
body.light-theme .token.punctuation,
body.light-theme .token.attr-name {
  color: #7c6f64; /* fg4 / gray1 */
}
body.light-theme .token.tag,
body.light-theme .token.tag .punctuation,
body.light-theme .token.doctype,
body.light-theme .token.builtin {
  color: #b57614; /* yellow2 */
}
body.light-theme .token.entity,
body.light-theme .token.number,
body.light-theme .token.symbol {
  color: #8f3f71; /* purple2 */
}
body.light-theme .token.property,
body.light-theme .token.constant,
body.light-theme .token.variable {
  color: #9d0006; /* red2 */
}
body.light-theme .token.string,
body.light-theme .token.char {
  color: #797403; /* green2 */
}
body.light-theme .token.attr-value,
body.light-theme .token.attr-value .punctuation {
  color: #7c6f64; /* fg4 / gray1 */
}
body.light-theme .token.url {
  color: #797403; /* green2 */
  text-decoration: underline;
}
body.light-theme .token.function {
  color: #b57614; /* yellow2 */
}
body.light-theme .token.regex {
  background: #797403; /* green2 */
}
body.light-theme .token.bold {
  font-weight: bold;
}
body.light-theme .token.italic {
  font-style: italic;
}
body.light-theme .token.inserted {
  background: #7c6f64; /* fg4 / gray1 */
}
body.light-theme .token.deleted {
  background: #9d0006; /* red2 */
}

body.dark-theme {
  --bg: #343434;
  --bg-accent: rgba(90, 90, 90, 0.05);
  --bg-hover: rgba(255, 255, 255, 0.1);
  --bg-active: rgba(255, 255, 255, 0.4);
  --text: #c9c9c9;
  --visited: #c66acb;
  --link: #5769c2;
  --active: #dc5c5c;
  --info: #969696;
  --tag: #af5800;
  --toggle: #525252;
  --toggle-accent: #777;
  --toggle-active: #888;
  --form-bg: #3b3b3b;
  --border: lightgrey;
  --border-accent: rgba(255, 255, 255, 0.4);
  --unselected-tab: grey;
  --error: #dc5c5c;
  --error-bg: #231010;
  --warning: #967f46;
  --mod: #4d9339;
  --card: #3d3d3d;
  --card-dragging: #4d4d4d;
  --placeholder: #2c2c2c;
  --deleting: #dc5c5c;
  --quote: #969696;
}
@media not print {
  body.dark-theme {
    color-scheme: dark;
    color: var(--text);
  }
  @supports not (color-scheme: dark) {
    body.dark-theme input[type=email], body.dark-theme input[type=url], body.dark-theme input[type=text], body.dark-theme input[type=number], body.dark-theme input[type=search], body.dark-theme textarea {
      appearance: none;
      color: var(--text);
      background-color: rgb(59, 59, 59);
      border: 1px solid rgb(133, 133, 133);
      border-radius: 2px;
    }
    body.dark-theme input[type=email] input:disabled, body.dark-theme input[type=url] input:disabled, body.dark-theme input[type=text] input:disabled, body.dark-theme input[type=number] input:disabled, body.dark-theme input[type=search] input:disabled, body.dark-theme textarea input:disabled {
      color: rgb(170, 170, 170);
    }
    body.dark-theme input[type=checkbox] {
      background-color: rgb(59, 59, 59);
    }
    body.dark-theme button {
      color: var(--text);
      appearance: none;
      background-color: rgb(107, 107, 107);
      border: 2px solid rgb(107, 107, 107);
      border-radius: 2px;
    }
    body.dark-theme button:disabled {
      color: rgb(170, 170, 170);
    }
    body.dark-theme:not(.macos) input::-webkit-calendar-picker-indicator, body.dark-theme:not(.macos) textarea::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }
    body.dark-theme:not(.macos) select:not(:disabled) {
      color: var(--text);
      background-color: rgb(59, 59, 59);
      border: 1px solid rgb(118, 118, 118);
      border-radius: 2px;
    }
  }
}
body.dark-theme {
  background-color: var(--bg);
  background-image: url("~/assets/bg-dark.png");
  background-blend-mode: soft-light;
}
body.dark-theme figure::backdrop, body.dark-theme .editor.fullscreen app-md {
  background-color: var(--bg);
  background-image: url("~/assets/bg-dark.png");
  background-blend-mode: soft-light;
}
body.dark-theme app-root > .container {
  background: radial-gradient(circle at center, transparent 0, rgba(0, 0, 0, 0.3));
}
body.dark-theme :link, body.dark-theme .link-style {
  color: var(--link);
}
body.dark-theme :not(.actions) :visited {
  color: var(--visited);
}
body.dark-theme a:active {
  color: var(--text);
}
body.dark-theme .toolbar label.on {
  backdrop-filter: contrast(0.1) brightness(0.6) blur(1.5px);
}
body.dark-theme .warning {
  color: var(--warning);
}
body.dark-theme .subscription-bar {
  background-color: var(--bg);
  border-bottom: 1px solid black;
}
body.dark-theme .subscription-bar :link, body.dark-theme .subscription-bar :visited, body.dark-theme .subscription-bar a:active {
  color: var(--text);
}
body.dark-theme .subscription-bar .current-page {
  color: var(--active);
}
body.dark-theme .subscription-bar .light-toggle {
  background-color: rgba(255, 255, 255, 0.1);
}
body.dark-theme .subscription-bar .light-toggle:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
body.dark-theme .tabs {
  background-color: rgba(100, 100, 100, 0.3);
}
body.dark-theme .tabs .logo {
  background-image: url("~/assets/jasper-dark.png");
}
body.dark-theme .tabs h5 {
  text-decoration: brown;
}
body.dark-theme .tabs > a:hover {
  background-color: #a7a7a7;
}
body.dark-theme .tabs > a.current-tab {
  color: var(--active);
  background-color: #252525;
  background-image: url("~/assets/bg-dark.png");
  background-blend-mode: soft-light;
  border-top: 1px solid var(--border);
  border-left: 1px solid var(--border);
  border-right: 1px solid var(--border);
  border-bottom: 1px solid transparent;
}
body.dark-theme .tabs > a.current-tab:nth-child(4) {
  background-color: #262626;
}
body.dark-theme .tabs > a.current-tab:nth-child(5) {
  background-color: #272727;
}
body.dark-theme .tabs > a.current-tab:nth-child(6) {
  background-color: #282828;
}
body.dark-theme .tabs > a.current-tab:nth-child(7) {
  background-color: #292929;
}
body.dark-theme .tabs > a.current-tab:nth-child(8) {
  background-color: #292929;
}
body.dark-theme .tabs > a.current-tab:nth-child(9) {
  background-color: #292929;
}
body.dark-theme .tabs > a.current-tab:nth-child(10) {
  background-color: #292929;
}
body.dark-theme .tabs > a.current-tab:nth-child(11) {
  background-color: #282828;
}
body.dark-theme .tabs > a.current-tab:nth-child(12) {
  background-color: #282828;
}
body.dark-theme .tabs select:hover {
  background-color: #a7a7a7;
}
body.dark-theme .sidebar {
  background-color: var(--bg-accent);
  backdrop-filter: grayscale(1) contrast(0.2) brightness(0.4) blur(1px);
  box-shadow: -2px 2px 2px 1px rgba(0, 0, 0, 0.1);
}
body.dark-theme details.advanced summary > span:after {
  filter: drop-shadow(0 0 2px var(--bg));
}
body.dark-theme .editor:focus-within, body.dark-theme .bubble, body.dark-theme .plugins-form > *, body.dark-theme blockquote, body.dark-theme .full-page .todo-list {
  background-color: var(--bg-accent);
  backdrop-filter: blur(1px);
}
body.dark-theme .thumbnail, body.dark-theme .image-expand, body.dark-theme video, body.dark-theme .embed-expand, body.dark-theme .qr-expand {
  filter: drop-shadow(0px 0px 0.5px #ffffff);
}
body.dark-theme .twitter iframe {
  filter: none !important;
}
body.dark-theme .thumbnail.default-thumbnail {
  filter: none;
}
body.dark-theme .comment-children {
  border-left: 1px dotted rgba(211, 211, 211, 0.3);
}
body.dark-theme .thread > .ref-list > .list-container > .ref,
body.dark-theme .md .ref.plugin_thread:not(.ref-list-item),
body.dark-theme .thread .ref.plugin_thread:not(.ref-list-item),
body.dark-theme .thread.ref {
  --thread-sent: rgba(32, 128, 255, 0.15);
  --thread-recieved: rgba(255, 255, 255, 0.1);
  box-shadow: 1px 1px 2.5px #000;
}
body.dark-theme .summary-box {
  background-color: var(--bg-accent);
  backdrop-filter: blur(1px);
}
body.dark-theme footer hr {
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.3), 0 6px 5px 1px rgba(0, 0, 0, 0.1);
}
body.dark-theme .kanban .column-title, body.dark-theme .kanban .swim-lane-title {
  background-color: var(--bg-accent);
  backdrop-filter: blur(1px);
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
}
body.dark-theme .kanban .column-title:hover, body.dark-theme .kanban .swim-lane-title:hover {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2), 0 0 1px rgba(255, 255, 255, 0.5);
}
body.dark-theme .kanban-card.cdk-drag-preview {
  background-color: var(--card-dragging);
}
body.dark-theme .todo-item.cdk-drag-preview {
  background-color: var(--card-dragging);
}
body.dark-theme .backgammon-board {
  --bg: rgba(178, 103, 41, 0.2);
  --black: #111;
  --red: #633;
  --play: rgba(255, 255, 0, 0.3);
  --brown: #431;
  --brown-alt: #653;
  --glow: rgba(200, 200, 200, 0.5);
  --move: rgba(0, 128, 0, 0.5);
}

/**
 * Darcula theme
 *
 * Adapted from a theme based on:
 * IntelliJ Darcula Theme (https://github.com/bulenkov/Darcula)
 *
 * @author Alexandre Paradis <service.paradis@gmail.com>
 * @version 1.0
 */
body.dark-theme {
  /* Code blocks */
  /* Inline code */
}
body.dark-theme code[class*=language-],
body.dark-theme pre[class*=language-] {
  color: #a9b7c6;
  font-family: Consolas, Monaco, "Andale Mono", monospace;
  direction: ltr;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
body.dark-theme pre[class*=language-]::-moz-selection, body.dark-theme pre[class*=language-] ::-moz-selection,
body.dark-theme code[class*=language-]::-moz-selection, body.dark-theme code[class*=language-] ::-moz-selection {
  color: inherit;
  background: rgba(33, 66, 131, 0.85);
}
body.dark-theme pre[class*=language-]::selection, body.dark-theme pre[class*=language-] ::selection,
body.dark-theme code[class*=language-]::selection, body.dark-theme code[class*=language-] ::selection {
  color: inherit;
  background: rgba(33, 66, 131, 0.85);
}
body.dark-theme pre[class*=language-] {
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
}
body.dark-theme :not(pre) > code[class*=language-],
body.dark-theme pre[class*=language-] {
  background: #2b2b2b;
}
body.dark-theme :not(pre) > code[class*=language-] {
  padding: 0.1em;
  border-radius: 0.3em;
}
body.dark-theme .token.comment,
body.dark-theme .token.prolog,
body.dark-theme .token.cdata {
  color: #808080;
}
body.dark-theme .token.delimiter,
body.dark-theme .token.boolean,
body.dark-theme .token.keyword,
body.dark-theme .token.selector,
body.dark-theme .token.important,
body.dark-theme .token.atrule {
  color: #cc7832;
}
body.dark-theme .token.operator,
body.dark-theme .token.punctuation,
body.dark-theme .token.attr-name {
  color: #a9b7c6;
}
body.dark-theme .token.tag,
body.dark-theme .token.tag .punctuation,
body.dark-theme .token.doctype,
body.dark-theme .token.builtin {
  color: #e8bf6a;
}
body.dark-theme .token.entity,
body.dark-theme .token.number,
body.dark-theme .token.symbol {
  color: #6897bb;
}
body.dark-theme .token.property,
body.dark-theme .token.constant,
body.dark-theme .token.variable {
  color: #9876aa;
}
body.dark-theme .token.string,
body.dark-theme .token.char {
  color: #6a8759;
}
body.dark-theme .token.attr-value,
body.dark-theme .token.attr-value .punctuation {
  color: #a5c261;
}
body.dark-theme .token.attr-value .punctuation:first-child {
  color: #a9b7c6;
}
body.dark-theme .token.url {
  color: #287bde;
  text-decoration: underline;
}
body.dark-theme .token.function {
  color: #ffc66d;
}
body.dark-theme .token.regex {
  background: #364135;
}
body.dark-theme .token.bold {
  font-weight: bold;
}
body.dark-theme .token.italic {
  font-style: italic;
}
body.dark-theme .token.inserted {
  background: #294436;
}
body.dark-theme .token.deleted {
  background: #484a4a;
}
body.dark-theme code.language-css .token.property,
body.dark-theme code.language-css .token.property + .token.punctuation {
  color: #a9b7c6;
}
body.dark-theme code.language-css .token.id {
  color: #ffc66d;
}
body.dark-theme code.language-css .token.selector > .token.class,
body.dark-theme code.language-css .token.selector > .token.attribute,
body.dark-theme code.language-css .token.selector > .token.pseudo-class,
body.dark-theme code.language-css .token.selector > .token.pseudo-element {
  color: #ffc66d;
}

/* Tablet and Bigger Screens */
@media (min-width: 740px) {
  .toggle.actions-toggle, .toggle.view, .toggle.threads, .toggle.comments {
    display: none;
  }
  .full-page-form app-ref {
    max-width: 80%;
    margin: auto;
  }
  .full-page-form .form {
    margin: auto;
  }
  .editor.add-button {
    min-width: min(100vw, 538px);
  }
  .mobile-block, .mobile-inline {
    display: none !important;
  }
}
/* Mobile Overrides */
@media not print {
  /* Tablet and Smaller Screens */
  @media (max-width: 948px) {
    .sidebar {
      position: static !important;
      float: none !important;
      backdrop-filter: blur(1px) !important;
      border: none !important;
      margin-left: 0;
    }
    .sidebar details {
      max-height: 42px;
    }
    .sidebar input[type=search] {
      width: 100%;
    }
    .sidebar > * {
      max-height: 1000px;
      overflow: hidden;
      transition: opacity 0.4s 0.1s, font-size 0.5s, margin 0.5s, padding 0.5s, border 0.5s, max-height 0.5s;
    }
    .sidebar:not(.expanded) .hide {
      transition: opacity 0.5s, font-size 0.4s 0.1s, margin 0.4s 0.1s, padding 0.4s 0.1s, border 0.4s 0.1s, max-height 0.4s 0.1s;
      font-size: 0 !important;
      margin: 0 !important;
      max-height: 0 !important;
      opacity: 0 !important;
      padding: 0 !important;
      border: 0 !important;
    }
    .sidebar:not(.expanded) .button-bar {
      flex-direction: row;
    }
    .sidebar:not(.expanded) .submit-button {
      display: flex;
      flex-grow: 1;
      margin: 0;
      margin-right: 4px;
      align-items: center;
    }
    .sidebar:not(.expanded) .submit-button:last-child {
      margin-right: 1px;
    }
    .sidebar:not(.expanded) .submit-button span {
      width: 100%;
      text-align: center;
    }
    .sidebar:not(.expanded) .submit-button label {
      display: none;
    }
    .todo-list, .kanban-column {
      padding-bottom: 4px !important;
    }
    .todo-list input, .kanban-column input {
      display: block !important;
    }
  }
  /* Phone Screens */
  @media (max-width: 740px) {
    input[type=color],
    input[type=date],
    input[type=datetime],
    input[type=datetime-local],
    input[type=email],
    input[type=month],
    input[type=number],
    input[type=password],
    input[type=search],
    input[type=tel],
    input[type=text],
    input[type=time],
    input[type=url],
    input[type=week],
    button,
    select,
    select:focus,
    textarea {
      font-size: 16px;
    }
    .mobile-col {
      flex-direction: column;
    }
    .mobile-hide {
      display: none !important;
    }
    .mobile-block {
      display: block !important;
    }
    .mobile-inline {
      display: inline !important;
    }
    .subscription-bar {
      height: 20px;
      padding: 2px 8px 2px 0;
    }
    .subscription-bar .subs {
      line-height: 20px;
      font-size: 13px;
      margin: 0 !important;
      padding: 0;
      mask-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.1) 6px, white 18px, white calc(100% - 18px), rgba(255, 255, 255, 0.1) calc(100% - 6px), rgba(255, 255, 255, 0));
    }
    .subscription-bar .light-toggle {
      font-size: 13px;
      padding: 2px 20px 15px 22px;
    }
    .tabs h5 {
      flex-basis: 100%;
    }
    .editor, .summary-box {
      background-color: unset !important;
      backdrop-filter: unset !important;
      box-shadow: unset !important;
      border: 1px solid transparent !important;
    }
    .toolbar {
      overflow-x: scroll;
    }
    .ref .editor, .ref .summary-box, .comment-edit .editor, .comment-edit .summary-box, .comment-reply .editor, .comment-reply .summary-box, .full-page-form .editor, .full-page-form .summary-box {
      margin: 0 !important;
      padding: 0 !important;
    }
    .editor.add-button > * {
      margin: 4px;
    }
    .thread > .ref-list > .list-container > .ref,
    .md .ref.plugin_thread:not(.ref-list-item),
    .thread .ref.plugin_thread:not(.ref-list-item),
    .parent-ref.thread,
    .full-page.ref.thread {
      margin: 4px 6px 4px 4px;
      padding: 0;
    }
    .thread > .ref-list > .list-container > .ref .thumbnail,
    .thread > .ref-list > .list-container > .ref .toggle.actions-toggle,
    .thread > .ref-list > .list-container > .ref .toggle.view,
    .thread > .ref-list > .list-container > .ref .toggle.threads,
    .thread > .ref-list > .list-container > .ref .toggle.comments,
    .md .ref.plugin_thread:not(.ref-list-item) .thumbnail,
    .md .ref.plugin_thread:not(.ref-list-item) .toggle.actions-toggle,
    .md .ref.plugin_thread:not(.ref-list-item) .toggle.view,
    .md .ref.plugin_thread:not(.ref-list-item) .toggle.threads,
    .md .ref.plugin_thread:not(.ref-list-item) .toggle.comments,
    .thread .ref.plugin_thread:not(.ref-list-item) .thumbnail,
    .thread .ref.plugin_thread:not(.ref-list-item) .toggle.actions-toggle,
    .thread .ref.plugin_thread:not(.ref-list-item) .toggle.view,
    .thread .ref.plugin_thread:not(.ref-list-item) .toggle.threads,
    .thread .ref.plugin_thread:not(.ref-list-item) .toggle.comments,
    .parent-ref.thread .thumbnail,
    .parent-ref.thread .toggle.actions-toggle,
    .parent-ref.thread .toggle.view,
    .parent-ref.thread .toggle.threads,
    .parent-ref.thread .toggle.comments,
    .full-page.ref.thread .thumbnail,
    .full-page.ref.thread .toggle.actions-toggle,
    .full-page.ref.thread .toggle.view,
    .full-page.ref.thread .toggle.threads,
    .full-page.ref.thread .toggle.comments {
      display: none;
    }
    .thread > .ref-list > .list-container > .ref:not(.sent),
    .md .ref.plugin_thread:not(.ref-list-item):not(.sent),
    .thread .ref.plugin_thread:not(.ref-list-item):not(.sent),
    .parent-ref.thread:not(.sent),
    .full-page.ref.thread:not(.sent) {
      justify-self: left;
    }
    .thread > .ref-list > .list-container > .ref.sent,
    .md .ref.plugin_thread:not(.ref-list-item).sent,
    .thread .ref.plugin_thread:not(.ref-list-item).sent,
    .parent-ref.thread.sent,
    .full-page.ref.thread.sent {
      margin-left: 4px;
      justify-self: right;
    }
    .thread > .ref-list > .list-container > .ref > .row > .stack,
    .md .ref.plugin_thread:not(.ref-list-item) > .row > .stack,
    .thread .ref.plugin_thread:not(.ref-list-item) > .row > .stack,
    .parent-ref.thread > .row > .stack,
    .full-page.ref.thread > .row > .stack {
      padding: 8px;
    }
    .thread > .ref-list > .list-container > .ref > .row > .stack > .link-below > .stack > .info, .thread > .ref-list > .list-container > .ref > .row > .stack > .link-below > .stack > .actions, .thread > .ref-list > .list-container > .ref > .row > .stack > .link,
    .md .ref.plugin_thread:not(.ref-list-item) > .row > .stack > .link-below > .stack > .info,
    .md .ref.plugin_thread:not(.ref-list-item) > .row > .stack > .link-below > .stack > .actions,
    .md .ref.plugin_thread:not(.ref-list-item) > .row > .stack > .link,
    .thread .ref.plugin_thread:not(.ref-list-item) > .row > .stack > .link-below > .stack > .info,
    .thread .ref.plugin_thread:not(.ref-list-item) > .row > .stack > .link-below > .stack > .actions,
    .thread .ref.plugin_thread:not(.ref-list-item) > .row > .stack > .link,
    .parent-ref.thread > .row > .stack > .link-below > .stack > .info,
    .parent-ref.thread > .row > .stack > .link-below > .stack > .actions,
    .parent-ref.thread > .row > .stack > .link,
    .full-page.ref.thread > .row > .stack > .link-below > .stack > .info,
    .full-page.ref.thread > .row > .stack > .link-below > .stack > .actions,
    .full-page.ref.thread > .row > .stack > .link {
      margin-left: 0;
      transition: none;
      height: 28px;
      line-height: 28px;
      padding: 0 8px;
    }
    .thread > .ref-list > .list-container > .ref > .row > .stack > .link,
    .md .ref.plugin_thread:not(.ref-list-item) > .row > .stack > .link,
    .thread .ref.plugin_thread:not(.ref-list-item) > .row > .stack > .link,
    .parent-ref.thread > .row > .stack > .link,
    .full-page.ref.thread > .row > .stack > .link {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .buttons.right {
      padding-right: 27px;
    }
    .list-container .list-number {
      opacity: 0;
      width: 0;
      min-width: 0;
      padding: 0;
      line-height: 0;
    }
    .list-container .link {
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }
    .form {
      padding: 0;
    }
    textarea {
      margin: 0;
      resize: vertical;
      width: 100%;
    }
    .full-page-form .editor textarea {
      margin-left: 0;
    }
    .app-comment-reply .editor, app-comment-edit .editor {
      width: calc(100% - 11px);
    }
    .app-comment-reply .editor textarea, app-comment-edit .editor textarea {
      margin-left: -11px;
      width: calc(100% + 5px);
    }
    .editor-label, .add-plugins-label {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .editor-label > label, .add-plugins-label > label {
      margin-right: 20px;
    }
    .editor-label > .select-plugin, .add-plugins-label > .select-plugin {
      flex: 1;
    }
    .json-editor ngx-monaco-editor {
      margin: 0 0 0 -10px;
      width: 100vw !important;
    }
    .plugins-form .json-editor ngx-monaco-editor,
    .plugins-form textarea {
      width: calc(100vw - 40px) !important;
    }
    .floating-ribbons > div {
      right: calc(23px - 100%);
    }
    ngx-monaco-editor {
      width: 100vw !important;
      max-height: 50vh;
    }
    .resize-handle:after {
      content: "⬍";
      font-size: 175%;
      width: 24px;
      height: 48px;
      line-height: 48px;
      background-image: none;
      right: 22px;
      bottom: -25px;
    }
    .voting {
      font-size: 170%;
    }
    .voting * {
      transform: none !important;
    }
    .comment {
      margin-left: 2px;
    }
    .comment .voting {
      margin-top: 10px;
    }
    .comment .comment-children {
      margin-left: 2px;
    }
    .comment-reply, .comment-edit {
      width: min(100%, 100vw - 16px);
    }
    .embed:not(.comment-body) {
      position: relative;
      left: 0;
      margin: 0;
      width: min(100%, 100vw - 16px);
    }
    .audio-expand {
      width: 100%;
      margin: 10px 0;
    }
    video {
      width: 100vw;
      height: 100vh;
    }
    .full-page .embed {
      width: calc(100vw - 10px);
    }
    .full-page.thread .embed {
      width: unset;
    }
    .info, .actions, .advanced-actions * {
      font-size: 16px;
      line-height: 28px;
      height: 28px;
      padding-top: 0;
      padding-bottom: 0;
      overflow-y: hidden;
      overflow-x: auto;
      white-space: nowrap;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
    .info::-webkit-scrollbar, .actions::-webkit-scrollbar, .advanced-actions *::-webkit-scrollbar {
      display: none;
    }
    .info a, .info .fake-link, .actions a, .actions .fake-link, .advanced-actions * a, .advanced-actions * .fake-link {
      line-height: 28px;
    }
    .ref {
      max-width: 100vw;
    }
    .ref .row {
      max-width: calc(100vw - 12px);
    }
    .ref .info, .ref .actions {
      height: 0;
      margin-left: -8px;
      padding: 0 8px;
      mask-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.1) 2px, white 8px, white calc(100% - 18px), rgba(255, 255, 255, 0.1) calc(100% - 6px), rgba(255, 255, 255, 0));
    }
    .ref .info.expanded, .ref .actions.expanded {
      transition: height 0.5s;
      height: 32px;
      padding-top: 2px;
    }
    .ref .info.not-expanded, .ref .actions.not-expanded {
      transition: height 0.5s;
    }
    .ref.full-page > .row > .stack > .link-below > .stack > *.info, .ref.full-page > .row > .stack > .link-below > .stack > *.actions {
      height: 32px !important;
      transition: none !important;
    }
    .ref.full-page > .row > .stack > .link-below > .toggle.actions-toggle, .ref.full-page > .row > .stack > .link-below > .toggle.view, .ref.full-page > .row > .stack > .link-below > .toggle.threads, .ref.full-page > .row > .stack > .link-below > .toggle.comments {
      display: none !important;
    }
    .show-more {
      opacity: 1 !important;
    }
    .expand {
      border: none !important;
    }
    .thread .image-expand {
      width: calc(100vw - 36px);
    }
    .fill-editor {
      margin: 0 !important;
    }
    .md blockquote {
      margin-right: auto;
    }
    .kanban-header .kanban-options > div {
      position: relative !important;
    }
    .badges .badge {
      transform: none !important;
      filter: none !important;
      border: 1px solid transparent !important;
    }
    .badges .badge.filtering {
      transform: translate(1px, -1px) !important;
      filter: drop-shadow(0 0 0.5px white) drop-shadow(1px 1px 0.5px black) !important;
      border: 1px solid rgba(0, 0, 0, 0.1) !important;
    }
    .badges .badge:active {
      transform: scale(1.1) !important;
      filter: drop-shadow(-1px -1px 0.5px black) !important;
      border: 1px solid rgba(0, 0, 0, 0.1) !important;
    }
    .cdk-overlay-pane .editor textarea {
      margin-left: 2px;
    }
    .form, .full-page-upload .list-container {
      max-width: calc(100vw - 8px);
      display: flex !important;
      flex-direction: column;
    }
    .form label, .full-page-upload .list-container label {
      text-align: left !important;
    }
  }
  /* Smallest possible */
  @media (max-width: 380px) {
    .mini-col {
      flex-direction: column;
    }
    .mini-col.reverse {
      flex-direction: column-reverse;
    }
    .mini-col > * {
      width: 100% !important;
      margin: 4px 0;
    }
  }
}
@media (max-width: 740px) {
  body.darwin .subscription-bar .light-toggle > *, body.ios .subscription-bar .light-toggle > * {
    margin-top: -5px;
  }
}

body.safari input, body.safari textarea {
  background-color: var(--form-bg);
}
body.safari .toggle {
  line-height: 22px;
}
body.safari .actions-toggle {
  line-height: 25px;
}

body.darwin.safari .subscription-bar .back-button {
  padding-top: 2px;
}
body.darwin.safari .subscription-bar .light-toggle {
  padding: 2px 20px 15px 22px;
}
body.darwin.safari input:not([type=checkbox]):not([type=radio]) {
  height: 28px;
}
body.darwin.safari .actions input, body.darwin.safari .actions button, body.darwin.safari .advanced-actions input, body.darwin.safari .advanced-actions button {
  position: relative;
  top: 1px;
  height: 16px !important;
  font-size: 100%;
}
body.darwin.safari .form-array button, body.darwin.safari .buttons button {
  min-height: 28px !important;
  min-width: 32px !important;
}
body.darwin.safari .sidebar .controls select {
  min-height: 22px;
  position: relative;
  top: 2px;
}
body.darwin.safari .form select,
body.darwin.safari formly-list-section .form-array > formly-field > formly-group select {
  font-size: 21px;
}
body.darwin.safari .form button,
body.darwin.safari formly-list-section .form-array > formly-field > formly-group button {
  min-height: 28px !important;
  padding: 1px 6px;
}
body.darwin.safari .form .shrink select,
body.darwin.safari formly-list-section .form-array > formly-field > formly-group .shrink select {
  margin-top: 4px;
}
body.darwin.safari .form .nested-form > label,
body.darwin.safari .form .field > label,
body.darwin.safari .form formly-field > label,
body.darwin.safari .form formly-group > label,
body.darwin.safari .form formly-wrapper-form-field > label,
body.darwin.safari .form formly-list-section > label,
body.darwin.safari formly-list-section .form-array > formly-field > formly-group .nested-form > label,
body.darwin.safari formly-list-section .form-array > formly-field > formly-group .field > label,
body.darwin.safari formly-list-section .form-array > formly-field > formly-group formly-field > label,
body.darwin.safari formly-list-section .form-array > formly-field > formly-group formly-group > label,
body.darwin.safari formly-list-section .form-array > formly-field > formly-group formly-wrapper-form-field > label,
body.darwin.safari formly-list-section .form-array > formly-field > formly-group formly-list-section > label {
  line-height: 28px;
}
body.darwin.safari .form > label,
body.darwin.safari formly-list-section .form-array > formly-field > formly-group > label {
  line-height: 28px;
}
body.darwin.safari select.big {
  font-size: 21px;
}
body.darwin.safari .search input[type=search] {
  margin-top: 0;
}
body.darwin.safari .search.centered {
  position: relative;
  top: 50%;
}
body.darwin.safari .search.centered input[type=search] {
  transform: translateY(-50%);
}

body.ios input:not([type=checkbox]):not([type=radio]) {
  height: 28px;
}
body.ios button[type=submit] {
  color: revert;
}
body.ios button {
  min-width: 30px !important;
  min-height: 30px !important;
  padding: 1px 6px;
}
body.ios .controls button {
  min-width: unset !important;
}
body.ios .toolbar button, body.ios .form-array button {
  min-width: 56px !important;
}
body.ios .controls {
  line-height: 30px;
}
body.ios select {
  height: 30px !important;
}
body.ios .shrink select {
  margin-top: 4px;
}
body.ios .actions input, body.ios .advanced-actions input {
  height: 20px !important;
}
body.ios .toggle .toggle-x {
  top: -1px;
}
body.ios .form .nested-form > label,
body.ios .form .field > label,
body.ios .form formly-field > label,
body.ios .form formly-group > label,
body.ios .form formly-wrapper-form-field > label,
body.ios .form formly-list-section > label,
body.ios formly-list-section .form-array > formly-field > formly-group .nested-form > label,
body.ios formly-list-section .form-array > formly-field > formly-group .field > label,
body.ios formly-list-section .form-array > formly-field > formly-group formly-field > label,
body.ios formly-list-section .form-array > formly-field > formly-group formly-group > label,
body.ios formly-list-section .form-array > formly-field > formly-group formly-wrapper-form-field > label,
body.ios formly-list-section .form-array > formly-field > formly-group formly-list-section > label {
  line-height: 30px;
}
body.ios .form > label,
body.ios formly-list-section .form-array > formly-field > formly-group > label {
  line-height: 30px;
}
body.ios body.fullscreen > * {
  display: block;
}

body.android .toggle .toggle-x {
  top: -1px;
}
body.android body.fullscreen > * {
  display: block;
}

.print-block, .print-inline {
  display: none !important;
}

.ref {
  break-inside: avoid;
}

@media print {
  .print-hide {
    display: none !important;
  }
  .print-block {
    display: block !important;
  }
  .print-inline {
    display: inline !important;
  }
  .login-popup,
  .subscription-bar,
  .tabs a,
  .tabs .settings,
  .sidebar,
  .toggle,
  .actions,
  .blog .icon,
  app-comment-reply,
  .mobile-tab-select,
  footer {
    display: none !important;
  }
  hr {
    box-shadow: none !important;
  }
  body, .container {
    background: none !important;
  }
  .tabs,
  .print-images,
  .thumbnail,
  .logo {
    color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  .tabs {
    min-height: 0;
    background-color: unset !important;
  }
  .tabs .logo {
    background-image: url("~/assets/jasper-light.png") !important;
  }
  .full-page .link {
    display: none;
  }
  .backup .list-container .list-number {
    max-height: unset;
    line-height: unset;
  }
  .image-expand {
    max-width: 100% !important;
  }
  .md code {
    border: unset !important;
    background-color: unset !important;
  }
  /**
  * Gruvbox light theme
  *
  * Based on Gruvbox: https://github.com/morhetz/gruvbox
  * Adapted from PrismJS gruvbox-dark theme: https://github.com/schnerring/prism-themes/blob/master/themes/prism-gruvbox-dark.css
  *
  * @author Michael Schnerring (https://schnerring.net)
  * @version 1.0
  */
  body.dark-theme {
    /* Code blocks */
    /* Inline code */
  }
  body.dark-theme code[class*=language-],
  body.dark-theme pre[class*=language-] {
    color: #3c3836; /* fg1 / fg */
    font-family: Consolas, Monaco, "Andale Mono", monospace;
    direction: ltr;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    line-height: 1.5;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
  }
  body.dark-theme pre[class*=language-]::-moz-selection,
  body.dark-theme pre[class*=language-] ::-moz-selection,
  body.dark-theme code[class*=language-]::-moz-selection,
  body.dark-theme code[class*=language-] ::-moz-selection {
    color: #282828; /* fg0 */
    background: #a89984; /* bg4 */
  }
  body.dark-theme pre[class*=language-]::selection,
  body.dark-theme pre[class*=language-] ::selection,
  body.dark-theme code[class*=language-]::selection,
  body.dark-theme code[class*=language-] ::selection {
    color: #282828; /* fg0 */
    background: #a89984; /* bg4 */
  }
  body.dark-theme pre[class*=language-] {
    padding: 1em;
    margin: 0.5em 0;
    overflow: auto;
  }
  body.dark-theme :not(pre) > code[class*=language-],
  body.dark-theme pre[class*=language-] {
    background: #f9f5d7; /* bg0_h */
  }
  body.dark-theme :not(pre) > code[class*=language-] {
    padding: 0.1em;
    border-radius: 0.3em;
  }
  body.dark-theme .token.comment,
  body.dark-theme .token.prolog,
  body.dark-theme .token.cdata {
    color: #7c6f64; /* fg4 / gray1 */
  }
  body.dark-theme .token.delimiter,
  body.dark-theme .token.boolean,
  body.dark-theme .token.keyword,
  body.dark-theme .token.selector,
  body.dark-theme .token.important,
  body.dark-theme .token.atrule {
    color: #9d0006; /* red2 */
  }
  body.dark-theme .token.operator,
  body.dark-theme .token.punctuation,
  body.dark-theme .token.attr-name {
    color: #7c6f64; /* fg4 / gray1 */
  }
  body.dark-theme .token.tag,
  body.dark-theme .token.tag .punctuation,
  body.dark-theme .token.doctype,
  body.dark-theme .token.builtin {
    color: #b57614; /* yellow2 */
  }
  body.dark-theme .token.entity,
  body.dark-theme .token.number,
  body.dark-theme .token.symbol {
    color: #8f3f71; /* purple2 */
  }
  body.dark-theme .token.property,
  body.dark-theme .token.constant,
  body.dark-theme .token.variable {
    color: #9d0006; /* red2 */
  }
  body.dark-theme .token.string,
  body.dark-theme .token.char {
    color: #797403; /* green2 */
  }
  body.dark-theme .token.attr-value,
  body.dark-theme .token.attr-value .punctuation {
    color: #7c6f64; /* fg4 / gray1 */
  }
  body.dark-theme .token.url {
    color: #797403; /* green2 */
    text-decoration: underline;
  }
  body.dark-theme .token.function {
    color: #b57614; /* yellow2 */
  }
  body.dark-theme .token.regex {
    background: #797403; /* green2 */
  }
  body.dark-theme .token.bold {
    font-weight: bold;
  }
  body.dark-theme .token.italic {
    font-style: italic;
  }
  body.dark-theme .token.inserted {
    background: #7c6f64; /* fg4 / gray1 */
  }
  body.dark-theme .token.deleted {
    background: #9d0006; /* red2 */
  }
}
@media screen {
  .electron .back-button {
    display: block;
  }
}